/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="5">
                <div className="copyright text-center text-grey text-xl-left text-muted">
                  © {new Date().getFullYear()} Fitness Industry Supplier Association
                </div>
              </Col>
              <Col xl="7">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink to="/" tag={Link} className="text-dark">Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/auth/supplierform" tag={Link} className="text-dark">
                      Host Supplier Registration
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/auth/buyerform" tag={Link} className="text-dark">
                      VIP Guest Registration
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/auth/login" tag={Link} className="text-dark">
                      Admin Login
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://www.example.com" target="_blank" className="text-dark">
                      License
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
