/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";

// Auth imports.
import { Auth } from "aws-amplify";
import { UserContext } from "../../../lib/UserContext";

class Login extends React.Component {
  static contextType = UserContext;
  state = {
    email: "",
    password: "",
    codeSent: false,
    codeFailed: false,
    disableSubmitButton: false
  };

  // Handle onChange event for inputs.
  handleInputChange = (event) => {
    // Reset login error message.
    this.setState((s) => ({...s, codeFailed: false }));

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  // Handle form submission.
  handleSubmit = async (event) => {
    event.preventDefault();

    Auth.forgotPassword(this.state.email)
      .then(data => {
        this.setState((s) => ({...s, codeFailed: false, codeSent: true}))
      })
      .catch(() => {
        this.setState((s) => ({...s, codeFailed: true, codeSent: false}))
      });
  };

  render() {
    return (
      <>
        <AuthHeader />
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <div className="text-center text-white text-muted mb-4">
                <span>Forgot Password</span>
              </div>
              <Card className="bg-secondary border-0 mb-0" style={{borderRadius: 20}}>
                <CardBody className="px-lg-5 py-lg-5">
                  {this.state.codeFailed && (
                    <Alert color="danger">
                      Your account information was entered incorrectly.
                    </Alert>
                  )}

                  {this.state.codeSent && (
                    <Alert color="success">
                      Verification code has been sent.
                    </Alert>
                  )}

                  <Form role="form" onSubmit={this.handleSubmit}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          placeholder="Email"
                          type="email"
                          required
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          onFocus={() =>
                            this.setState((s) => ({ ...s, focusedEmail: true }))
                          }
                          onBlur={() =>
                            this.setState((s) => ({
                              ...s,
                              focusedEmail: false,
                            }))
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="dark" type="submit" disabled={this.state.disableSubmitButton}>
                        Send Code
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-dark"
                    href="/auth/login"
                  >
                    <small>Login</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
