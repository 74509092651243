/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Dropdown,
  Media,
  Navbar,
  Nav,
  Container,
  Col,
} from "reactstrap";

import { Auth } from "aws-amplify";
import { UserContext } from "../../lib/UserContext";

class AdminNavbar extends React.Component {
  static contextType = UserContext;

  state = {};

  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  logoutHandler = async (event) => {
    event.preventDefault();

    try {
      await Auth.signOut();

      // Update our user context provider.
      // Once this state change is picked
      // up by React, it will automatically
      // redirect user to login page.
      this.context.setState((state) => ({ ...state, user: null }));
    } catch (error) {}
  };

  render() {
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-dark": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" },
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <div
                className={classnames(
                  "pr-3 sidenav-toggler",
                  { "sidenav-toggler-dark": this.props.theme === "dark" },
                )}
                onClick={this.props.toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
              <Form
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                  { "navbar-search-light": this.props.theme === "dark" },
                  { "navbar-search-dark": this.props.theme === "light" },
                )}
              >
                <FormGroup className="mb-0 mr-3">
                  <Dropdown 
                    isOpen={this.state.dropdownTenantOpen} 
                    toggle={() => this.setState(s => { return { ...s, dropdownTenantOpen: !this.state.dropdownTenantOpen} })}
                  >
                    <DropdownToggle caret>
                      {this.context.activeTenant ? this.context.activeTenant.name : "No Current Organization"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.context.tenants.map((item, index) => (
                        <DropdownItem key={index} onClick={() => { this.context.selectTenant(item) }}>
                          {item.name}
                        </DropdownItem>)
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
                {this.context.activeTenant && <FormGroup className="mb-0">
                  <Dropdown 
                    isOpen={this.state.dropdownOpen} 
                    toggle={() => this.setState(s => { return { ...s, dropdownOpen: !this.state.dropdownOpen} })}
                  >
                    <DropdownToggle caret>
                      {this.context.activeEvent ? this.context.activeEvent.name : "No Current Event"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.context.events.map((item, index) => (
                        <DropdownItem key={index} onClick={() => { this.context.selectEvent(item) }}>
                          {item.name} ({new Date(item.startDate).toLocaleDateString()}-{new Date(item.endDate).toLocaleDateString()})
                        </DropdownItem>)
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>}
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={this.closeSearch}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </Form>
              <Col />
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fas fa-user" />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.context.user ? this.context.user.attributes.email : ""}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={this.logoutHandler}>
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
