// AWS Amplify settings.
export const AmplifyConfig = {
  development: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-west-1",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "us-west-1_nJHidVMKk",

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "2ekmpgk72sd3oj4212le38mqcm",
    },
  },
  production: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-west-1",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "us-west-1_nJHidVMKk",

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "2ekmpgk72sd3oj4212le38mqcm",
    },
  },
};
