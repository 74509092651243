/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardImg, CardBody, Container, Row, Col } from "reactstrap";

class IndexHeader extends React.Component {

  render() {
    return (
      <>
        <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundColor: "#c61308"}}>
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  {this.props.title ? (
                    <h1 className="text-red">{this.props.title}</h1>
                  ) : null}
                  {this.props.lead ? (
                    <p className="text-lead text-red">{this.props.lead}</p>
                  ) : null}
                  {this.props.logo ? (
                    <img src="https://fisana.org/wp-content/uploads/2014/08/fisa-logo.png" alt="logo"/>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                style={{fill: "#f8f9fe"}}
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7" className="mt--7">
              <div className="text-center text-muted mb-4">
                <h1 className="text-white">FISA EVENTS</h1>
                <div className="small text-white">Never been to a FISA event before?</div>
                <div className="small text-white">See how our events work.</div>
                <a
                  className="mt-2 btn btn-sm btn-outline-white text-white"
                  href="https://youtu.be/RwfS4UKVURs"
                  tag={Link}
                >
                  Watch the Video
                </a>
              </div>
              <Card className="bg-secondary" style={{ borderRadius: 20 }}>
                <CardBody className="text-center text-dark px-lg-7 py-lg-3">
                  <span className="font-weight-bold">Register for a FISA Event</span><br />
                  <Col>
                    <Button
                      className="my-2 w-100"
                      color="dark"
                      to="/auth/buyerform"
                      tag={Link}
                    >
                      Register as a VIP Guest
                    </Button>
                  </Col>
                </CardBody>
                <CardImg 
                  bottom 
                  style={{ borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}
                  width="100%" 
                  src="https://fisana.org/wp-content/uploads/2014/09/3image_banner3.jpg" 
                  alt="image" 
                />
              </Card>
              <Card className="bg-secondary border-0 mb-0" style={{borderRadius: 20}}>
                <CardBody className="text-center px-lg-7 py-lg-3">
                  <div className="text-center text-dark mb-4">
                    <span className="font-weight-bold">Register as a Host Supplier</span><br />
                    <small>Click the button below to create a new Host Supplier account.</small>
                  </div>
                  <Col>
                    <Button
                      className="mt--2 mb-2 w-100 px-0"
                      color="dark"
                      to="/auth/supplierform"
                      tag={Link}
                    >
                      Register as a Host Supplier
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="my-2 w-100 btn-sm"
                      color="danger"
                      to="/auth/appointments"
                      tag={Link}
                    >
                      Login to Make Appointments
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="7" md="6" >
              <div className="text-center text-dark m-4">
                <span className="font-weight-bold">About FISA Events</span><br />
                <div className="w-100">
                  <div
                    className="video"
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%" /* 16:9 */,
                      paddingTop: 25,
                      height: 0,
                    }}
                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                      }}
                      src="https://www.youtube.com/embed/RwfS4UKVURs" 
                      title="FISA"
                      frameBorder="0"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <div className="text-center text-dark mt-3">
                <span className="font-weight-bold">About FISA</span><br />
                <small>The Fitness Industry Suppliers Association of North America is a 501(c) 3 non-profit organization. We provide market intelligence and unique events that enable our members to better understand the commercial sector and meet the needs of commercial facilities and their customers.</small><br />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IndexHeader;
