/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import EventsHeader from "components/Headers/EventsHeader.js";
import { UserContext } from "../../lib/UserContext";
import classnames from "classnames";
import axiosInstance from "api";
import AppointmentForm from "components/Forms/AppointmentForm"

class Timeline extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const eventAttendeeId = localStorage.getItem('eventAttendeeId');

    this.state = {
      event: null,
      eventAttendeeId: eventAttendeeId === null || eventAttendeeId === 'null' ? null : eventAttendeeId,
      email: "",
      error: null,
      noAvailableEvents: false,
    };

    this.signOut = this.signOut.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    if(this.state.eventAttendeeId != null) {
      this.getAvailableEvents(this.state.eventAttendeeId);
    }
  }

  getAvailableEvents = async (eventAttendeeId) => {
    if (eventAttendeeId) {
      this.context.setState((s) => {
        return { ...s, loading: true };
      });
      axiosInstance
        .get(`registration/scheduling-available-events/${eventAttendeeId}`)
        .then((resp) => {
          const event = resp.data[0];
          if (event) {
            this.setState((s) => {
              return { ...s, event };
            });
          } else {
            this.signOut();
          }
        })
        .finally(() => {
          this.context.setState((s) => {
            return { ...s, loading: false };
          });
        });
    }
  }

  handleInputChange = (event) => {
    // Reset login error message.
    this.setState((s) => ({ ...s, loginFailed: false }));

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };


  // Handle form submission.
  handleSubmit = async (event) => {
    event.preventDefault();

    this.context.setState((s) => ({ ...s, loading: true }));
    axiosInstance
      .get(`registration/findEventAttendeeId/${this.state.email}`)
      .then(async (resp) => {
        await this.getAvailableEvents(resp.data);
        this.setState((s) => ({
          ...s,
          eventAttendeeId: resp.data,
          loginFailed: !resp.data,
        }));
        localStorage.setItem('eventAttendeeId', resp.data)
      })
      .finally(() => {
        this.context.setState((s) => {
          return { ...s, loading: false };
        });
      });
  };

  signOut = () => {
    localStorage.setItem('eventAttendeeId', null)
    this.setState({ eventAttendeeId: null })
  }

  onError = (err) => {
    this.setState({ error: err })
  }

  render() {
    return (
      <>
        <EventsHeader title="Appointment Scheduler" />
        {this.state.eventAttendeeId && this.state.event &&(
          <Container className="mt--6" fluid>
            <Row>
              <Col md="8" className="offset-md-2">
                <Card>
                  <AppointmentForm event={this.state.event} eventAttendeeId={this.state.eventAttendeeId} email={this.state.email} onError={this.onError} signOut={this.signOut} />
                </Card>
              </Col>
            </Row>
            <Modal isOpen={!!this.state.error}>
              <ModalHeader toggle={() => { this.setState({error: null}) }}>Please Note</ModalHeader>
              <ModalBody>
                {this.state.error}
              </ModalBody>
            </Modal>
          </Container>
        )}
        {!this.state.eventAttendeeId && (
          <Container className="mt--6 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card
                  className="bg-secondary border-0 mb-0"
                  style={{ borderRadius: 20 }}
                >
                  <CardBody className="px-lg-5 py-lg-5">
                    {this.state.loginFailed && (
                      <Alert color="danger">
                        Your account information was entered incorrectly or this event is not allowing scheduled appointments
                      </Alert>
                    )}

                    <Form role="form" onSubmit={this.handleSubmit}>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: this.state.focusedEmail,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                            required
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            onFocus={() =>
                              this.setState((s) => ({
                                ...s,
                                focusedEmail: true,
                              }))
                            }
                            onBlur={() =>
                              this.setState((s) => ({
                                ...s,
                                focusedEmail: false,
                              }))
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="dark"
                          type="submit"
                          disabled={this.context.loading}
                        >
                          Sign in
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        {this.state.noAvailableEvents && (
          <Container className="mt--6" fluid>
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card>
                  <CardHeader className="text-center">
                    No Available Events
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

export default Timeline;
