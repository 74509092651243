import axios from 'axios';
import { Auth } from 'aws-amplify';
import { apiConfig } from '../config';

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl
});

axiosInstance.interceptors.request.use(
  async config => {
    config.headers["Access-Control-Allow-Origin"] = "*";

    if (Auth.user) {
      const session = await Auth.currentSession();
      const accessToken = session.getIdToken();

      config.headers["Authorization"] = `Bearer ${accessToken.getJwtToken()}`;
      config.headers["Tenant"] = localStorage.getItem('tenantId');
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;