/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { apiConfig } from '../../config';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import EventForm from "components/Forms/EventForm"
import { UserContext } from "lib/UserContext";
import copy from 'copy-to-clipboard';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  sizePerPage: 50,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class Events extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ selectedItem: {...row} })
      }
    };

    this.state = {
      rowEvents,
      selectedItem: null,
      error: null,
    };

    this.onError = this.onError.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  onCopy = (type) => {
    const location = window.location.href.substr(0, window.location.href.indexOf("admin")) + "auth/";
    if (type === "supplier") {
      copy(location + "supplierform?event=" + this.state.selectedItem.id);
    } else if (type === "buyer") {
      copy(location + "buyerform?event=" + this.state.selectedItem.id);
    } else if (type === "photos") {
      copy(apiConfig.baseUrl + "registration/photos/" + this.state.selectedItem.id);
    }
  }

  onReset = () => {
    this.setState({ error: null, selectedItem: null })
    const activeTenant = this.context.activeTenant;
    this.context.setState(s => ({...s, activeTenant: null}))
    this.context.selectTenant(activeTenant);
  }

  onError = (err) => {
    this.setState({ error: err })
  }

  isReadOnly = (event) => {
    if (!event || !event.eventAppointmentBlocks) {
      return false;
    }

    return event.eventAppointmentBlocks
      .some(block => block.eventAppointmentTimes
        .some(slot => slot.eventAppointments && slot.eventAppointments.length > 0));
  }

  render() {
    return (
      <>
        <SimpleHeader name="Events" parentName="Home" />
        {this.context.activeTenant && <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              {this.state.selectedItem === null &&
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Events</h3>
                </CardHeader>
                <ToolkitProvider
                  data={this.context.activeTenant ? this.context.events : []}
                  keyField="id"
                  columns={[
                    {
                      headerStyle: { color: "black" },
                      dataField: "name",
                      text: "Event Name",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "startDate",
                      text: "Start Date",
                      sort: true,
                      formatter: (c) => {
                        return new Date(c).toLocaleDateString()
                      }
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "endDate",
                      text: "End Date",
                      sort: true,
                      formatter: (c) => {
                        return new Date(c).toLocaleDateString()
                      }
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "practiceEvent",
                      text: "Status",
                      formatter: (c, r) => {
                        if (r.practiceEvent) {
                          return "Practice Event";
                        } else if (r.registrationsAvailable) {
                          return "Live Event";
                        } else {
                          return "Inactive Event";
                        }
                      }
                    },
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter w-100 px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                        <Button color="dark" className="float-right btn-sm mr-2" onClick={() => this.setState({selectedItem: {attachments: {attachment_1: null, attachments_2: null, attachkemt_3: null}}})}>Create Event</Button>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        rowEvents={this.state.rowEvents}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>}
              {this.state.selectedItem !== null && 
              <Card>
                <CardHeader>
                  <Button close onClick={() => this.setState({selectedItem: null})}/>
                  {this.state.selectedItem.id && <>
                    <h3>{this.state.selectedItem.name}</h3>
                  </>}
                  {!this.state.selectedItem.id && <>
                    <h3 className="mb-0">Create New Event </h3>
                  </>}
                  <Button color="success" className="btn-sm" onClick={() => this.onCopy("supplier")}>Copy Supplier Registration Link</Button>
                  <Button color="success" className="btn-sm mr-2" onClick={() => this.onCopy("buyer")}>Copy Buyer Registration Link</Button>
                  <Button color="success" className="btn-sm mr-2" onClick={() => this.onCopy("photos")}>Share Registration Photos</Button>
                </CardHeader>
                <EventForm data={this.state.selectedItem} readonly={this.isReadOnly(this.state.selectedItem)} onError={this.onError} onSuccess={this.onReset} />
              </Card>}
            </div>
          </Row>
          <Modal isOpen={!!this.state.error}>
            <ModalHeader toggle={() => { this.setState({error: null}) }}>Error</ModalHeader>
            <ModalBody>
              {this.state.error}
            </ModalBody>
          </Modal>
        </Container>}
        {!this.context.activeTenant && <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  No Tenant Selected
                </CardHeader>
              </Card>
            </div>
          </Row>
        </Container>}
      </>
    );
  }
}

export default Events;
