/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import DatePicker from "react-datepicker";
import "../../assets/css/datepicker.css";
// reactstrap components
import {
  CardHeader,
  CardBody,
  Label,
  Form,
  FormGroup,
  Button,
  Input,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import moment from "moment-timezone";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import CardFooter from "reactstrap/lib/CardFooter";
import axiosInstance from "api";
import { UserContext } from "lib/UserContext";
// import Avatar from "react-avatar-edit";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import NumberFormat from 'react-number-format';
class Elements extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.data,
      hotelArrivalDate: this.props.data.hotelArrivalDate ? new Date(this.props.data.hotelArrivalDate) : null,
      hotelDepartureDate: this.props.data.hotelDepartureDate ? new Date(this.props.data.hotelDepartureDate) : null,
      datetoAddNewFacilities: this.props.data.datetoAddNewFacilities ? new Date(this.props.data.datetoAddNewFacilities) : null,
      flightDepartureDate: this.props.data.flightDepartureDate ? new Date(this.props.data.flightDepartureDate) : null,
      flightArrivalDate: this.props.data.flightArrivalDate ? new Date(this.props.data.flightArrivalDate) : null,
      birthDate: this.props.data.birthDate ? new Date(this.props.data.birthDate) : null,
      country: this.props.data.country ? this.props.data.country : "United States",
      state: this.props.data.state ? this.props.data.state : "",
      equipmentOptions: [
        {
          key: "cardioTrainingEquipment",
          title: "Equipment",
          label: "Cardio Training Equipment - with entertainment attachments",
        },
        {
          key: "freeWeights",
          label: "Free Weights (dumbbells, Olympic plates & bars)",
        },
        {
          key: "strengthTrainingEquipmentPlateLoaded",
          label: "Strength Training Equipment-Plate Loaded",
        },
        {
          key: "strengthTrainingFunctional",
          label: "Strength Training-Functional",
        },
        { key: "youthFitnessEquipment", label: "Youth Fitness Equipment" },
        { key: "recovery", label: "Recovery" },
        {
          key: "therapeuticTreatments",
          label: "Therapeutic Treatments (red light therapy, cryotherapy, etc.)"
        },
        {
          key: "architecturalSupport",
          title: "Capital Expenses",
          label: "Architectural Support",
        },
        { key: "facilityDesign", label: "Facility Design" },
        { key: "flooringAndSportSurfaces", label: "Flooring & Sport Surfaces" },
        { key: "lockers", label: "Lockers" },
        {
          key: "bootCamps",
          title: "Revenue Producing Programs",
          label: "Boot Camps",
        },
        {
          key: "fitnessAssessmentTesting",
          label: "Fitness Assessments & Testing",
        },
        { key: "heartRateTraining", label: "Heart Rate Training" },
        { key: "juiceBar", label: "Juice Bar" },
        {
          key: "massageSpaProductsServices",
          label: "Massage/Spa Products & Services",
        },
        {
          key: "personalTrainingProgramsSupport",
          label: "Personal Training Programs & Support",
        },
        { key: "pilatesPrograms", label: "Pilates Programs" },
        { key: "seniorPrograms", label: "Senior Programs" },
        { key: "smallGroupTraining", label: "Small Group Training" },
        { key: "sportsSpecificTraining", label: "Sports Specific Training" },
        {
          key: "traditionalGroupExerciseClasses",
          label: "Traditional Group Exercise Classes",
        },
        {
          key: "weightManagementPrograms",
          label: "Weight Management Programs",
        },
        {
          key: "wellnessProgramsCorporate",
          label: "Wellness Programs Corporate & Others",
        },
        { key: "yogaPrograms", label: "Yoga Programs" },
        { key: "youthFitnessProgram", label: "Youth Fitness Programs" },
        { key: "recovery", label: "Recovery" },
        {
          key: "therapeuticTreatments",
          label: "Therapeutic Treatments (red light therapy, cryotherapy, etc.)"
        },
        {
          key: "membershipMarketing",
          title: "Marketing & Sales Support",
          label: "Membership Marketing",
        },
        { key: "membershipIncentive", label: "Membership Incentive" },
        { key: "membershipRetention", label: "Membership Retention" },
        { key: "salesAutomation", label: "Sales Automation" },
        { key: "salesTraining", label: "Sales Training" },
        { key: "socialMediaSupport", label: "Social Media Support" },
        {
          key: "aquaticUVSaltController",
          title: "Operations Management Support",
          label: "Aquatic Products & Programs",
        },
        { key: "EWFTBillingServices", label: "EFT Billing & Collection Services" },
        {
          key: "greenEnergyProductsPrograms",
          label: "Green Energy Products/Programs",
        },
        {
          key: "leasingFinancingofEquipment",
          label: "Leasing & Financing of Equipment",
        },
        { key: "managementSoftware", label: "Management Software" },
        { key: "sanitationProducts", label: "Sanitation Products" },
      ],
      ymcaPrograms: [
        { key: "ymcaAbsCore", label: "Abs / Core" },
        { key: "ymcaActiveAgingSenior", label: "Active Aging / Senior" },
        { key: "ymcaAquaticWater", label: "Aquatic / Water" },
        { key: "ymcaBarreBallet", label: "Barre / Ballet-based" },
        { key: "ymcaBiking", label: "Biking" },
        { key: "ymcaBoxingMartialArts", label: "Boxing / Kick Boxing / Martial Arts" },
        { key: "ymcaCircuitTraining", label: "Circuit Training" },
        { key: "ymcaDance", label: "Dance-based" },
        { key: "ymcaFitnessAssessmentsConsultations", label: "Fitness Assessments / Consultations" },
        { key: "ymcaFunctionalTraining", label: "Functional Training" },
        { key: "ymcaHeartRateTraining", label: "Heart Rate Training" },
        { key: "ymcaIndoorCycling", label: "Indoor Cycling" },
        { key: "ymcaKidSpecific", label: "Kid Specific" },
        { key: "ymcaMassage", label: "Massage" },
        { key: "ymcaNutrition", label: "Nutrition" },
        { key: "ymcaPersonalTrainingIndividual", label: "Personal Training-Individual" },
        { key: "ymcaPersonalTrainingSmallGroup", label: "Personal Training-Small Group" },
        { key: "ymcaPilatesEquipmentBased", label: "Pilates – Equipment Based" },
        { key: "ymcaPilatesMat", label: "Pilates – Mat" },
        { key: "ymcaRehab", label: "Rehab" },
        { key: "ymcaRowing", label: "Rowing" },
        { key: "ymcaRunning", label: "Running" },
        { key: "ymcaSportSpecificTraining", label: "Sport Specific Training" },
        { key: "ymcaStrengthTraining", label: "Strength Training" },
        { key: "ymcaTaiChi", label: "Tai Chi" },
        { key: "ymcaTreadmill", label: "Treadmill" },
        { key: "ymcaVirtualTraining", label: "Virtual Training" },
        { key: "ymcaYoga", label: "Yoga" },
      ],
      ymcaProgramsWithPriority: [
        { key: "ymcaAbsCorePriority", label: "Abs / Core" },
        { key: "ymcaActiveAgingSeniorPriority", label: "Active Aging / Senior" },
        { key: "ymcaAquaticWaterPriority", label: "Aquatic / Water" },
        { key: "ymcaBarreBalletPriority", label: "Barre / Ballet-based" },
        { key: "ymcaBikingPriority", label: "Biking" },
        { key: "ymcaBoxingMartialArtsPriority", label: "Boxing / Kick Boxing / Martial Arts" },
        { key: "ymcaCircuitTrainingPriority", label: "Circuit Training" },
        { key: "ymcaDancePriority", label: "Dance-based" },
        { key: "ymcaFitnessAssessmentsConsultationsPriority", label: "Fitness Assessments / Consultations" },
        { key: "ymcaFunctionalTrainingPriority", label: "Functional Training" },
        { key: "ymcaHeartRateTrainingPriority", label: "Heart Rate Training" },
        { key: "ymcaIndoorCyclingPriority", label: "Indoor Cycling" },
        { key: "ymcaKidSpecificPriority", label: "Kid Specific" },
        { key: "ymcaMassagePriority", label: "Massage" },
        { key: "ymcaNutritionPriority", label: "Nutrition" },
        { key: "ymcaPersonalTrainingIndividualPriority", label: "Personal Training-Individual" },
        { key: "ymcaPersonalTrainingSmallGroupPriority", label: "Personal Training-Small Group" },
        { key: "ymcaPilatesEquipmentBasedPriority", label: "Pilates – Equipment Based" },
        { key: "ymcaPilatesMatPriority", label: "Pilates – Mat" },
        { key: "ymcaRehabPriority", label: "Rehab" },
        { key: "ymcaRowingPriority", label: "Rowing" },
        { key: "ymcaRunningPriority", label: "Running" },
        { key: "ymcaSportSpecificTrainingPriority", label: "Sport Specific Training" },
        { key: "ymcaStrengthTrainingPriority", label: "Strength Training" },
        { key: "ymcaTaiChiPriority", label: "Tai Chi" },
        { key: "ymcaTreadmillPriority", label: "Treadmill" },
        { key: "ymcaVirtualTrainingPriority", label: "Virtual Training" },
        { key: "ymcaYogaPriority", label: "Yoga" },
      ],
      ymcaPositionsOfInfluence: [
        { key: "ymcaGroupFitnessManagerInfluence", label: "Group Fitness Manager (if not self)" },
        { key: "ymcaPersonalTrainingManagerInfluence", label: "Personal Training Manager (if not self)" },
        { key: "ymcaGeneralManagerInfluence", label: "General Manager" },
        { key: "ymcaCommitteeInfluence", label: "Committee" },
        { key: "ymcaSelfOnlyInfluence", label: "Self Only" },
      ],
      genderOptions: [
        "Male",
        "Female"
      ],
      facilitySQFTOptions: [
        "Less than 30,000 sq ft",
        "30,000 - 50,000 sq ft",
        "50,000 - 75,000 sq ft",
        "75,000 - 100,000 sq ft",
        "100,000 - 125,000 sq ft",
        "125,000 - 150,000 sq ft",
        "150,000 - 200,000 sq ft",
        "Greater than 200,000 sq ft"
      ],
      duesStructureOptions: [
        "Under $25",
        "$25 - $50",
        "$50 - $75",
        "$75 - $100",
        "$100 - $125",
        "Over $125"
      ],
      aggregateMembershipOptions: [
        "Less than 1,000",
        "1,000",
        "2,000",
        "3,000",
        "4,000",
        "5,000",
        "6,000",
        "7,000",
        "8,000",
        "9,000",
        "10,000+",
        "Prefer not to answer"
      ],
      purchasingTimeFrameOptions: [
        "1-3 Months",
        "4-6 Months",
        "6-8 Months",
        "8-12 Months",
        "More than 12 Months"
      ],
      personnelCountOptions: [
        "1-25",
        "25-50",
        "50-100",
        "100-150",
        "150-200",
        "200-250",
        "250-300",
        "300+"
      ],
      scaleOptions: [
        "1",
        "2",
        "3",
        "4",
        "5",
      ],
      preview: null,
      src: "",
      activeIndex: 0,
      animating: false,
      showSchedule: false,
      attachments: this.props.data.attachments ?? {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this)
    this.validateForm = this.validateForm.bind(this);
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    this.canUploadImage = this.canUploadImage.bind(this)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.allAttachmentsPresent = this.allAttachmentsPresent.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCellPhoneChange = this.handleCellPhoneChange.bind(this);

    moment.tz.setDefault(this.props.data.timeZone);
  }

  componentDidMount() {
    if (this.props.data) {
      if (this.props.data.imageString) {
        this.setState({editProfilePic: false, src: this.props.data.imageString});
      } else {
        this.setState({editProfilePic: true, src: ""});
      }
    }
  }

  componentDidUpdate() {
    moment.tz.setDefault(this.props.data.timeZone);
  }

  allAttachmentsPresent = () => {
    let allPresent = true;
    for (const key of Object.keys(this.props.data.attachments)) {
      if (!this.props.data.attachments[key]) {
        allPresent = false;
      }
    }
    return allPresent;
  }

  canUploadImage = () => {
    if (this.props.data) {
      if (this.props.data.imageString) {
        return true;
      }
    }

    return false;
  }

  next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === Object.keys(this.props.data.attachments).length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? Object.keys(this.props.data.attachments).length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({activeIndex: newIndex});
  }

  onClose() {
    this.setState({preview: null})
  }
  
  onCrop(preview) {
    this.setState({preview})
  }

  onBeforeFileLoad(elem) {
    if(elem.target.files[0].size > 71680){
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  handleCountryChange = (value) => {
    this.setState({country: value});
  }

  handleRegionChange = (value) => {
    this.setState({state: value});
  }

  handlePhoneChange = (e) => {
    this.setState({phone: e.value});
  }

  handleCellPhoneChange = (e) => {
    this.setState({cellphone: e.value});
  }

  handleMultipleCheckboxes = (event) => {
    const { target } = event;
    const { name, value } = target;
    this.setState({
      [ name ]: value,
    });
  }

  handleChange = async (event) => {
    const uppercaseFirstLetter = (v) => v.charAt(0).toUpperCase() + v.slice(1);
    const uppercaseFirstLetterLowercaseRest = (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
    const { target } = event;
    const { name } = target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (name === "firstName" || name === "lastName") {
      const regex = /[A-Z]{3,100}/;
      value = uppercaseFirstLetter(value);
      if (regex.test(value)) {
        value = uppercaseFirstLetterLowercaseRest(value);
      }
    }
    await this.setState({
      [ name ]: value,
    });
  }

  handleDateChange = async (name, value) => {
    await this.setState({
      [ name ]: value,
    });
  }

  validateForm() {
    if (this.props.verifyEmail && this.state.email !== this.state.verifyEmail) {
      this.props.onError("Email and Verify Email fields do not match.");
      return false;
    }

    return true;
  }

  submitForm(e) {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const equipmentOptions = {};
    this.state.equipmentOptions.forEach(item => {
      equipmentOptions[item.key] = this.state[item.key] || false;
    })

    const ymcaPrograms = {};
    this.state.ymcaPrograms.forEach(item => {
      ymcaPrograms[item.key] = this.state[item.key] || false;
    });

    const ymcaProgramsWithPriority = {};
    this.state.ymcaProgramsWithPriority.forEach(item => {
      ymcaProgramsWithPriority[item.key] = this.state[item.key] || false;
    })

    const ymcaPositionsOfInfluence = {}
    this.state.ymcaPositionsOfInfluence.forEach(item => {
      ymcaPositionsOfInfluence[item.key] = this.state[item.key] || false;
    })

    const payload = {
      eventId: this.props.eventId,
      registration: {
        id: this.state.registrationId,
        personId: this.state.personId,
        buyer: true,
        primaryRepresentative: this.state.primaryRepresentative,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        birthDate: this.state.birthDate,
        suffix: this.state.suffix || "",
        title: this.state.title,
        badgeName: this.state.badgeName,
        organizationName: this.state.organizationName,
        gender: this.state.gender || this.state.genderOptions[0],
        email: this.state.email,
        webUrl: this.state.webUrl,
        phone: this.state.phone,
        cellphone: this.state.cellphone,
        preferCellphone: this.state.preferCellphone,
        dietaryRestrictions: this.state.dietaryRestrictions,
        hotelArrivalDate: this.state.hotelArrivalDate.toDateString(),
        hotelDepartureDate: this.state.hotelDepartureDate.toDateString(),
        hotelConfirmationNumber: this.state.hotelConfirmationNumber,
        hotelShareRoom: this.state.hotelShareRoom,
        singleRoom: this.state.singleRoom,
        hotelShareRoomPartner: this.state.hotelShareRoomPartner,
        guestOfGuestTotalDue: this.state.guestOfGuestTotalDue,
        guestOfGuestFeesDetails: this.state.guestOfGuestFeesDetails,
        specialArrangementsFees: this.state.specialArrangementsFees,
        nightsPaidByFisa: this.state.nightsPaidByFisa,
        nightsPaidByGuest: this.state.nightsPaidByGuest,
        bedsNeeded: this.state.bedsNeeded,
        singleOccupancy: this.state.singleOccupancy,
        submittedDateTime: new Date(),
        imageString: this.state.preview
      },
      buyerRegistration: {
        id: this.state.id,
        registrationId: this.state.registrationId,
        invitedBy: this.state.invitedBy,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        zip: this.state.zip,
        noFlightNeeded: this.state.noFlightNeeded,
        rentingCar: this.state.rentingCar,
        requiresGroundTransport: this.state.requiresGroundTransport,
        rentalCarDetails: this.state.rentalCarDetails,
        departureCity: this.state.departureCity,
        alternateDepartureCity: this.state.alternateDepartureCity,
        frequentFlyerAirline1: this.state.frequentFlyerAirline1,
        frequentFlyerNumber1: this.state.frequentFlyerNumber1,
        frequentFlyerAirline2: this.state.frequentFlyerAirline2,
        frequentFlyerNumber2: this.state.frequentFlyerNumber2,
        boardingPassName: this.state.boardingPassName || "",
        flightDepartureDate: this.state.flightDepartureDate,
        flightArrivalDate: this.state.flightArrivalDate,
        facilityCount: this.state.facilityCount,
        outdoorPlayground: this.state.outdoorPlayground,
        indoorDayCare: this.state.indoorDayCare,
        outdoorDayCare: this.state.outdoorDayCare,
        facilitiesLocations: this.state.facilitiesLocations,
        facilitySQFTMax: this.state.facilitySQFTMax || this.state.facilitySQFTOptions[0],
        facilitySQFTMin: this.state.facilitySQFTMin || this.state.facilitySQFTOptions[0],
        duesStructure: this.state.duesStructure || this.state.duesStructureOptions[0],
        aggregateMembership: this.state.aggregateMembership || this.state.aggregateMembershipOptions[0],
        planToAddNewFacilities: this.state.planToAddNewFacilities,
        newFacilitiesCount: this.state.newFacilitiesCount,
        datetoAddNewFacilities: this.state.datetoAddNewFacilities,
        geographicalAreas: this.state.geographicalAreas,
        ...equipmentOptions,
        buyerPriority1: this.state.buyerPriority1 || this.state.equipmentOptions[0].key,
        buyerPriority2: this.state.buyerPriority2 || this.state.equipmentOptions[0].key,
        buyerPriority3: this.state.buyerPriority3 || this.state.equipmentOptions[0].key,
        fiscalYearEnd: this.state.fiscalYearEnd,
        purchasingTimeFrame: this.state.purchasingTimeFrame || this.state.purchasingTimeFrameOptions[0],
        budget: this.state.budget,
        comments: this.state.comments,
        assistantName: this.state.assistantName,
        assistantEmail: this.state.assistantEmail,

        ymcaPersonalTrainersCount: this.state.ymcaPersonalTrainersCount || this.state.personnelCountOptions[0],
        ymcaGroupFitnessInstructorsCount: this.state.ymcaGroupFitnessInstructorsCount || this.state.personnelCountOptions[0],
        ymcaFacilityLocations: this.state.ymcaFacilityLocations,
        ymcaActivitiesOfInterest: this.state.ymcaActivitiesOfInterest,
        ...ymcaPrograms,
        ...ymcaProgramsWithPriority,
        ymcaOpportunityGrowth: this.state.ymcaOpportunityGrowth,
        ymcaProgramDevelopmentInfluence: this.state.ymcaProgramDevelopmentInfluence || this.state.scaleOptions[0],
        ymcaFinancialDecisionsInfluence: this.state.ymcaFinancialDecisionsInfluence || this.state.scaleOptions[0],
        ymcaLargeProductsInfluence: this.state.ymcaLargeProductsInfluence || this.state.scaleOptions[0],
        ymcaSmallProductsInfluence: this.state.ymcaSmallProductsInfluence || this.state.scaleOptions[0],
        ...ymcaPositionsOfInfluence,
        ymcaOtherInfluence: this.state.ymcaOtherInfluence,
        ymcaContractualAgreementSupplier1: this.state.ymcaContractualAgreementSupplier1,
        ymcaContractualAgreementSupplier2: this.state.ymcaContractualAgreementSupplier2,
        ymcaContractualAgreementSupplier3: this.state.ymcaContractualAgreementSupplier3,
        ymcaContractualAgreementSupplier4: this.state.ymcaContractualAgreementSupplier4,
        ymcaContractualAgreementSupplier5: this.state.ymcaContractualAgreementSupplier5,
        ymcaContractualAgreementDuration1: this.state.ymcaContractualAgreementDuration1,
        ymcaContractualAgreementDuration2: this.state.ymcaContractualAgreementDuration2,
        ymcaContractualAgreementDuration3: this.state.ymcaContractualAgreementDuration3,
        ymcaContractualAgreementDuration4: this.state.ymcaContractualAgreementDuration4,
        ymcaContractualAgreementDuration5: this.state.ymcaContractualAgreementDuration5,
        ymcaContractualAgreementProducts1: this.state.ymcaContractualAgreementProducts1,
        ymcaContractualAgreementProducts2: this.state.ymcaContractualAgreementProducts2,
        ymcaContractualAgreementProducts3: this.state.ymcaContractualAgreementProducts3,
        ymcaContractualAgreementProducts4: this.state.ymcaContractualAgreementProducts4,
        ymcaContractualAgreementProducts5: this.state.ymcaContractualAgreementProducts5,
        archived: this.state.archived ?? false
      },
    }

    this.context.setState(s => { return { ...s, loading: true }});
    axiosInstance.post("/registration/insertBuyer", payload)
      .then(resp => {
        this.props.onSuccess();
      })
      .catch(err => {
        this.props.onError("There was an error with the request. Please check the fields and try again.");
      })
      .finally(() => {
        this.context.setState(s => { return { ...s, loading: false }});
      })
  }

  Event = ({ event }) => {
    return (
      <span>
        <strong>{event.title}</strong>
        {event.desc && ":  " + event.desc}
      </span>
    );
  };

  EventAgenda = ({ event }) => {
    return (
      <span className="w-100">
        <span style={{ color: "default" }}>
          <span className="text-muted">
            <i className="fas fa-calendar mr-2" /> {event.title}
          </span>
        </span>
      </span>
    );
  };


  render() {
    const eventAppointmentBlocks = (this.state.blocks
      ? this.state.blocks
      : []
    ).map((item, index) => ({
      id: index,
      title: item.blockName,
      start: new Date(item.blockStartDateTime),
      end: new Date(item.blockEndDateTime),
    }));

    const events = [...eventAppointmentBlocks];

    return (
      <>
        <style>
          {
            `.carousel {
               max-width: 650px;
             }
             .carousel-item {
               max-width: 650px;
               max-height: 300px;
               height: 100%;
               background: grey;
             }`
          }
        </style>
        <Form className="form" onSubmit={(e) => this.submitForm(e)}>
          <CardBody>
          {this.props.data.attachments && this.allAttachmentsPresent() && (
            <Row className="mb-5 justify-content-center align-items-center">
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                className="carousel"
              >
                <CarouselIndicators items={Object.keys(this.props.data.attachments)} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                {Object.keys(this.props.data.attachments).map((key) => {
                    if (this.props.data.attachments[key]) {
                      return (
                        <CarouselItem
                          className="carousel-item"
                          onExiting={() => this.setState({animating: true})}
                          onExited={() => this.setState({animating: false})}
                          key={key}
                        >
                          <img src={this.props.data.attachments[key]} alt="Event attachment" style={{maxHeight: 300, width: "100%"}} />
                        </CarouselItem>  
                      )
                    } else {
                      return (
                        <CarouselItem
                          className="carousel-item"
                          onExiting={() => this.setState({animating: true})}
                          onExited={() => this.setState({animating: false})}
                          key={key}
                        >
                          <></>
                        </CarouselItem>  
                      )
                    }
                  })}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
              </Row>
            )}
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="firstName"
                  >
                    First Name *
                  </label>
                  <Input
                    id="firstName"
                    name="firstName"
                    value={this.state.firstName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="First Name"
                    type="text"
                    required
                    disabled={this.props.readonly}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="lastName"
                  >
                    Last Name *
                  </label>
                  <Input
                    id="lastName"
                    name="lastName"
                    value={this.state.lastName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Last Name"
                    type="text"
                    required
                    disabled={this.props.readonly}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="title"
                  >
                    Job Title *
                  </label>
                  <Input
                    id="title"
                    name="title"
                    value={this.state.title || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Job Title"
                    type="text"
                    required
                    disabled={this.props.readonly}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="badgeName"
                  >
                    Badge Name *
                  </label>
                  <Input
                    id="badgeName"
                    name="badgeName"
                    value={this.state.badgeName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="First Name on Badge"
                    type="text"
                    required
                    disabled={this.props.readonly}
                  />
                </FormGroup>
              </Col>
            </Row>
            {!this.props.hidePersonalInfo && <>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="invitedBy"
                    >
                      Referred By
                    </label>
                    <Input
                      id="invitedBy"
                      name="invitedBy"
                      value={this.state.invitedBy || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Referred By"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>}
          </CardBody>
          {this.props.ymca && (
            <>
            <CardHeader>
              <h3 className="mb-0">YMCA Organization Information</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="organizationName"
                    >
                      YMCA Organization Name *
                    </label>
                    <Input
                      id="organizationName"
                      name="organizationName"
                      value={this.state.organizationName || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Company/Information Name"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Number of Personal Trainers *
                    </label>
                    <Input 
                      name="ymcaPersonalTrainersCount"
                      value={this.state.ymcaPersonalTrainersCount || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.personnelCountOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Number of Group Fitness Instructors *
                    </label>
                    <Input 
                      name="ymcaGroupFitnessInstructorsCount"
                      value={this.state.ymcaGroupFitnessInstructorsCount || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.personnelCountOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            </>
          )}
          {!this.props.hidePersonalInfo && <>
            <CardHeader>
              <h3 className="mb-1">Assistant Information</h3>
              <p className="mb-0 form-control-label">If you would like a copy of your appointment schedule and final hotel and travel confirmation
              sent to an administrative assistant or business partner, please give us their contact information.</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="assistantName"
                    >
                      Assistant Name
                    </label>
                    <Input
                      id="assistantName"
                      name="assistantName"
                      value={this.state.assistantName || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Assistant Name"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="assistantEmail"
                    >
                      Assistant Email
                    </label>
                    <Input
                      id="assistantEmail"
                      name="assistantEmail"
                      value={this.state.assistantEmail || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Assistant Email"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </>
          }
          {!this.props.ymca && (
            <>
            <CardHeader>
              <h3 className="mb-0">Company Information</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="organizationName"
                    >
                      Company/Organization Name *
                    </label>
                    <Input
                      id="organizationName"
                      name="organizationName"
                      value={this.state.organizationName || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Company/Information Name"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            </>
          )}
          {!this.props.hidePersonalInfo && <>
            <CardHeader>
              <h3 className="mb-0">Your Preferred {this.props.ymca ? "YMCA Organization" : "Business"} Mailing Address</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="addressLine1"
                    >
                      Address 1 *
                    </label>
                    <Input
                      id="addressLine1"
                      name="addressLine1"
                      value={this.state.addressLine1 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Address 1"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="addressLine2"
                    >
                      Address 2
                    </label>
                    <Input
                      id="addressLine2"
                      name="addressLine2"
                      value={this.state.addressLine2 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Address 2"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="city"
                    >
                      City *
                    </label>
                    <Input
                      id="city"
                      name="city"
                      value={this.state.city || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="City"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="country"
                    >
                      Organization Country *
                    </label>
                    <CountryDropdown
                      id="country"
                      name="country"
                      value={this.state.country || ""}
                      onChange={ (e) => this.handleCountryChange(e) }
                      className="form-control"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="state"
                    >
                      State/Province *
                    </label>
                    <RegionDropdown
                      id="state"
                      name="state"
                      country={this.state.country || ""}
                      value={this.state.state || ""}
                      onChange={ (e) => this.handleRegionChange(e) }
                      className="form-control"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="zip"
                    >
                      Zip/Postal Code *
                    </label>
                    <Input
                      id="zip"
                      name="zip"
                      value={this.state.zip || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Zip/Postal Code"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardHeader>
              <h3 className="mb-0">Communication Profile</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="phone"
                    >
                      Phone *
                    </label>
                    <NumberFormat
                      id="phone"
                      name="phone"
                      value={this.state.phone || ""}
                      onValueChange={ (e) => this.handlePhoneChange(e) }
                      placeholder="+1 (___) ___ - ____"
                      format="+1 (###) ###-####"
                      mask="_"
                      required
                      className="form-control"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="cellphone"
                    >
                      Cellphone *
                    </label>
                    <NumberFormat
                      id="cellphone"
                      name="cellphone"
                      value={this.state.cellphone || ""}
                      onValueChange={ (e) => this.handleCellPhoneChange(e) }
                      placeholder="+1 (___) ___ - ____"
                      format="+1 (###) ###-####"
                      mask="_"
                      required
                      className="form-control"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label
                    className="form-control-label"
                  >
                    What's the best way to reach you?
                  </label>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        name="preferCellphone"
                        checked={this.state.preferCellphone || ""}
                        onChange={ (e) => this.handleChange(e) }
                        type="checkbox" 
                      />
                        <label className="form-control-label" style={{color: "#32325d"}}>I prefer cellphone</label>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="email"
                    >
                      Email *
                    </label>
                    <Input
                      id="email"
                      name="email"
                      value={this.state.email || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Email"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {this.props.verifyEmail && <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="verifyEmail"
                    >
                      Verify Email *
                    </label>
                    <Input
                      id="verifyEmail"
                      name="verifyEmail"
                      value={this.state.verifyEmail || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Verify Email"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>}
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="webUrl"
                    >
                      Website *
                    </label>
                    <Input
                      id="webUrl"
                      name="webUrl"
                      value={this.state.webUrl || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Website"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardHeader>
              <h3 className="mb-0">Hotel & Transportation Information</h3>
              <p className="mb-0" style={{fontWeight: 500}}>FISA will cover up to 3 room nights</p>
            </CardHeader>
            <CardBody>
              {this.state.blocks && this.state.blocks.length > 0 && (
                <>
                  <Row className="justify-content-end">
                    <Button
                      color="danger"
                      disabled={this.context.loading}
                      onClick={() => this.setState({showSchedule: !this.state.showSchedule})}
                      className="mb-2"
                    >{this.state.showSchedule ? "Hide Event Schedule" : "View Event Schedule"}</Button>
                  </Row>
                  {this.state.showSchedule && (
                    <>
                      <Row className="pb-3 pt-3 align-items-center">
                        <Col>
                          <Calendar
                            toolbar={false}
                            selectable
                            localizer={momentLocalizer(moment)}
                            events={events}
                            views={[Views.AGENDA]}
                            defaultView={Views.AGENDA}
                            scrollToTime={
                              this.state.startDate
                                ? new Date(moment(this.state.startDate).startOf("week"))
                                : new Date()
                            }
                            defaultDate={
                              this.state.startDate
                                ? new Date(moment(this.state.startDate).startOf("week"))
                                : new Date()
                            }
                            components={{
                              event: this.Event,
                              agenda: {
                                event: this.EventAgenda,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="hotelArrivalDate"
                    >
                      Hotel Arrival Date *
                    </label>
                    <br />
                    <DatePicker
                      selected={this.state.hotelArrivalDate}
                      onChange={(date) => this.handleDateChange("hotelArrivalDate", date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                      dropdownMode="select"
                      customInput={<Input disabled={this.props.readonly} />}
                      disabled={this.props.readonly}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="hotelDepartureDate"
                    >
                      Hotel Departure Date *
                    </label>
                    <br />
                    <DatePicker
                      selected={this.state.hotelDepartureDate}
                      onChange={(date) => this.handleDateChange("hotelDepartureDate", date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                      customInput={<Input disabled={this.props.readonly} />}
                      disabled={this.props.readonly}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        name="singleOccupancy"
                        checked={this.state.singleOccupancy || ""}
                        onChange={ (e) => this.handleChange(e) }
                        type="checkbox" 
                      />
                        <label className="form-control-label" style={{color: "#32325d"}}>I would like a single hotel room (Single Occupancy)</label>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              OR
              <br />
              <br />
              <Row>
                <Col>
                  <label
                    className="form-control-label"
                  >
                    Are you sharing a hotel room with someone?
                  </label>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        name="hotelShareRoom"
                        checked={this.state.hotelShareRoom || ""}
                        onChange={ (e) => this.handleChange(e) }
                        type="checkbox" 
                      />
                        <label className="form-control-label" style={{color: "#32325d"}}>Yes, I am sharing a hotel room</label>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              {this.state.hotelShareRoom && <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="hotelShareRoomPartner"
                    >
                      Who are you sharing a room with?
                    </label>
                    <Input
                      id="hotelShareRoomPartner"
                      name="hotelShareRoomPartner"
                      value={this.state.hotelShareRoomPartner || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Full Name"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="bedsNeeded"
                    >
                      Number of hotel beds needed
                    </label>
                    <Input 
                      id="bedsNeeded"
                      name="bedsNeeded"
                      value={this.state.bedsNeeded || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="1 or 2 Beds"
                      type="number" 
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>}
              <p className="form-control-label">
              Please note that our “guest of a guest” policy is as follows: <br />
                Your VIP invitation covers one fully paid guest per organization. If you would like to bring an additional person, please contact the FISA Event Manager to make those arrangements. All ‘guest of guest’ fees are passed along AT OUR COSTS.  A second guest pays for the cost of their roundtrip coach flight, the cost of a second room if needed, and catered meals if desired. The Sunday reception is complimentary. The dinners (including receptions) on Monday and Tuesday are $200 per each night. The cost for all catered meals is $750.
              </p>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="dietaryRestrictions"
                    >
                      Dietary Restrictions
                      <p className="form-control-label">
                      We will do our best to offer healthy food choices in all catered meals. Do you have any special dietary restrictions i.e. do you require gluten-free or vegetarian meals?
                      </p>
                    </label>
                    <Input
                      id="dietaryRestrictions"
                      name="dietaryRestrictions"
                      value={this.state.dietaryRestrictions || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Dietary Restrictions"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <label className="form-control-label">Transportation</label>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        name="noFlightNeeded"
                        checked={this.state.noFlightNeeded || ""}
                        onChange={ (e) => this.handleChange(e) }
                        type="checkbox" 
                      />
                        <label className="form-control-label" style={{color: "#32325d"}}>I will drive to the event</label>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="departureCity"
                    >
                      Flight Departure City
                    </label>
                    <Input
                      id="departureCity"
                      name="departureCity"
                      value={this.state.departureCity || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Flight Departure City"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="alternateDepartureCity"
                    >
                      Flight Alternate City
                    </label>
                    <Input
                      id="alternateDepartureCity"
                      name="alternateDepartureCity"
                      value={this.state.alternateDepartureCity || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Flight Alternate City"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <label className="form-control-label">Frequent Flyers Program</label>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="frequentFlyerAirline1"
                    >
                      Name of Airline 1
                    </label>
                    <Input
                      id="frequentFlyerAirline1"
                      name="frequentFlyerAirline1"
                      value={this.state.frequentFlyerAirline1 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Name of Airline"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="frequentFlyerNumber1"
                    >
                      Frequent Flyer Account Number
                    </label>
                    <Input
                      id="frequentFlyerNumber1"
                      name="frequentFlyerNumber1"
                      value={this.state.frequentFlyerNumber1 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Frequent Flyer No"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="frequentFlyerAirline2"
                    >
                      Name of Airline 2
                    </label>
                    <Input
                      id="frequentFlyerAirline2"
                      name="frequentFlyerAirline2"
                      value={this.state.frequentFlyerAirline2 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Name of Airline"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="frequentFlyerNumber2"
                    >
                      Frequent Flyer Account Number
                    </label>
                    <Input
                      id="frequentFlyerNumber2"
                      name="frequentFlyerNumber2"
                      value={this.state.frequentFlyerNumber2 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Frequent Flyer No"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="flightDepartureDate"
                    >
                      Flight Departure Date (Depart Home)
                    </label>
                    <DatePicker
                      selected={this.state.flightDepartureDate}
                      onChange={(date) => this.handleDateChange("flightDepartureDate", date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                      customInput={<Input disabled={this.props.readonly} />}
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="flightArrivalDate"
                    >
                      Flight Return Date (Return Home)
                    </label>
                    <DatePicker
                      selected={this.state.flightArrivalDate}
                      onChange={(date) => this.handleDateChange("flightArrivalDate", date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                      customInput={<Input disabled={this.props.readonly} />}
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="boardingPassName"
                    >
                      Name on your Driver's License * (Name must match airline ticket and photo i.d.)
                    </label>
                    <Input
                      id="boardingPassName"
                      name="boardingPassName"
                      value={this.state.boardingPassName || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Name on your Driver's License"
                      type="text"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="birthDate"
                    >
                      Date of Birth *
                    </label>
                    <DatePicker
                      selected={this.state.birthDate}
                      onChange={(date) => this.handleDateChange("birthDate", date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<Input disabled={this.props.readonly} />}
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="gender"
                    >
                      Gender *
                    </label>
                    <Input 
                      id="gender"
                      name="gender"
                      value={this.state.gender || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.genderOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            </>
          }
          {this.props.ymca && (
            <>
            <CardHeader id="facility-header">
              <h3 className="mb-0">Facility & Programming Profile</h3>
              <p className="mb-0" style={{fontWeight: 500}}>
                All profile questions in this section are required fields.
                Providing this brief profile now will eliminate the need to repeat it during each of your appointments.
              </p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      What topics or programmatic activities would you like to discuss with a colleague or colleagues while at this event? *
                    </label>
                    <Input
                      name="ymcaActivitiesOfInterest"
                      value={this.state.ymcaActivitiesOfInterest || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Activities of Interest"
                      type="textarea"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <label className="form-control-label">Which of these programs or services do you currently offer on a fee-for-service-basis? *</label>
              <br />
              {this.state.ymcaPrograms.map((item, index) =>
                <Row key={index}>
                  <Col>
                  {!!item.title && (<label className="pt-3 form-control-label">{item.title}</label>)}
                    <FormGroup check>
                      <Label check>
                        <Input 
                          id={item.key}
                          name={item.key}
                          checked={this.state[item.key] || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                          <label className="form-control-label" style={{color: "#32325d"}}>{item.label}</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <br />
              <label className="form-control-label">Which of fee-based programs or services are a priority for improvement or implementation? *</label>
              <br />
              {this.state.ymcaProgramsWithPriority.map((item, index) =>
                <Row key={index}>
                  <Col>
                  {!!item.title && (<label className="pt-3 form-control-label">{item.title}</label>)}
                    <FormGroup check>
                      <Label check>
                        <Input 
                          id={item.key}
                          name={item.key}
                          checked={this.state[item.key] || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                        <label className="form-control-label" style={{color: "#32325d"}}>{item.label}</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      If your organization has multiple locations, how many locations are in the system? *
                    </label>
                    <Input
                      name="ymcaFacilityLocations"
                      value={this.state.ymcaFacilityLocations || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Total Number of Locations"
                      type="number"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      What would you describe as your primary opportunity for growth in the upcoming year (e.g. group ex participation, 1-on-1 personal training, small group personal training, other)? Please note, these answers will only be seen by Suppliers.
                    </label>
                    <Input
                      name="ymcaOpportunityGrowth"
                      value={this.state.ymcaOpportunityGrowth || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Opportunity for Growth"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <p className="form-control-label">On a scale of 1-5 (1 being least and 5 being most), how much influence do you have over:</p>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Program Development & Implementation *
                    </label>
                    <Input 
                      name="ymcaProgramDevelopmentInfluence"
                      value={this.state.ymcaProgramDevelopmentInfluence || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.scaleOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Financial Decisions related to programming development & implementation *
                    </label>
                    <Input 
                      name="ymcaFinancialDecisionsInfluence"
                      value={this.state.ymcaFinancialDecisionsInfluence || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.scaleOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Selection of large products (traditional equipment such as selectorized, plate-loaded, free-weights) *
                    </label>
                    <Input 
                      name="ymcaLargeProductsInfluence"
                      value={this.state.ymcaLargeProductsInfluence || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.scaleOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Selection of small products ($500 and under) *
                    </label>
                    <Input 
                      name="ymcaSmallProductsInfluence"
                      value={this.state.ymcaSmallProductsInfluence || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.scaleOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <p className="form-control-label">Do other staff members have influence over programming and purchasing at your facility (check all that apply):</p>
              {this.state.ymcaPositionsOfInfluence.map((item, index) =>
                <Row key={index}>
                  <Col>
                  {!!item.title && (<label className="pt-3 form-control-label">{item.title}</label>)}
                    <FormGroup check>
                      <Label check>
                        <Input 
                          id={item.key}
                          name={item.key}
                          checked={this.state[item.key] || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                          <label className="form-control-label" style={{color: "#32325d", fontSize: "0.875rem", fontWeight: 600}}>{item.label}</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}
               <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                     Other
                    </label>
                    <Input
                      name="ymcaOtherInfluence"
                      value={this.state.ymcaOtherInfluence || ""}
                      onChange={ (e) => this.handleChange(e) }
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardHeader>
              <h3 className="mb-0">Current Purchase Agreements</h3>
              <p className="mb-0" style={{fontWeight: 500}}>Briefly list the following information about your current purchase agreements. For answers use the space below.</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12" lg="4">
                  <p className="form-control-label" style={{height: 60}}>With which supplier do you have contractual agreement?</p>
                  {[...Array(5)].map((item, index) => (
                    <Input
                      key={index}
                      name={`ymcaContractualAgreementSupplier${index+1}`}
                      value={this.state[`ymcaContractualAgreementSupplier${index+1}`] || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  ))}
                </Col>
                <Col md="12" lg="4">
                  <p className="form-control-label" style={{height: 60}}>What is the duration of the agreement?</p>
                  {[...Array(5)].map((item, index) => (
                    <Input
                      key={index}
                      name={`ymcaContractualAgreementDuration${index+1}`}
                      value={this.state[`ymcaContractualAgreementDuration${index+1}`] || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  ))}
                </Col>
                <Col md="12" lg="4">
                  <p className="form-control-label" style={{height: 60}}>What products and/or services are included within each agreement?</p>
                  {[...Array(5)].map((item, index) => (
                    <Input
                      key={index}
                      name={`ymcaContractualAgreementProducts${index+1}`}
                      value={this.state[`ymcaContractualAgreementProducts${index+1}`] || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  ))}
                </Col>
              </Row>
              {/* <div className="ml-1 mt-4">
                <label
                  className="form-control-label"
                  htmlFor="dietaryRestrictions"
                >
                  Profile Picture
                  <p>
                    Please upload a headshot photo for your Profile. This photo will appear in the Event Booklet  (which is black and white) and distributed to all attendees.
                    Upload a photo in the resolution range of 300 to 600 dpi.
                    Click the profile picture box to upload your image. (jpg or png)
                  </p>
                </label>
                <br/>
                {this.canUploadImage()
                ? (
                  <>
                    <img src={this.state.imageString} alt="Preview" />
                    {!this.props.readonly && <div><Button color="danger" disabled={this.context.loading} onClick={this.props.deletePhoto} className="ml-2">Delete</Button></div>}
                  </>
                  ) : (
                    <>
                      {!this.props.readonly && (<Avatar
                        width={280}
                        height={280}
                        onCrop={this.onCrop}
                        onClose={this.onClose}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        src={this.state.src}
                        exportAsSquare={true}
                        exportSize={280}
                        label="Profile Picture"
                      />)}
                    </>
                  )}
              </div> */}
            </CardBody>
            </>
          )}
          {!this.props.ymca && (
            <>
            <CardHeader>
              <h3 className="mb-0">Business Profile</h3>
              <p className="mb-0" style={{fontWeight: 500}}>Providing this brief profile now will eliminate the need to repeat this information during each of your appointments</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="facilityCount"
                    >
                      Total Number of Facilities *
                    </label>
                    <Input
                      id="facilityCount"
                      name="facilityCount"
                      value={this.state.facilityCount || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Total Number of Facilities"
                      type="number"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="facilitiesLocations"
                    >
                      Locations of Facilities (Geographic Region) *
                    </label>
                    <Input
                      id="facilitiesLocations"
                      name="facilitiesLocations"
                      value={this.state.facilitiesLocations || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Location of Facilities"
                      type="textarea"
                      required
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="facilitySQFTMax"
                    >
                      Sq Ft of Largest Facility *
                    </label>
                    <Input 
                      id="facilitySQFTMax"
                      name="facilitySQFTMax"
                      value={this.state.facilitySQFTMax || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.facilitySQFTOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="facilitySQFTMin"
                    >
                      Sq Ft of Smallest Facility *
                    </label>
                    <Input 
                      id="facilitySQFTMin"
                      name="facilitySQFTMin"
                      value={this.state.facilitySQFTMin || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.facilitySQFTOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="duesStructure"
                    >
                      Individual Monthly Dues Structure *
                    </label>
                    <Input 
                      id="duesStructure"
                      name="duesStructure"
                      value={this.state.duesStructure || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.duesStructureOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="aggregateMembership"
                    >
                      Total Members in Club or Club Chain *
                    </label>
                    <Input 
                      id="aggregateMembership"
                      name="aggregateMembership"
                      value={this.state.aggregateMembership || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.aggregateMembershipOptions.map((item, index) => {
                        return <option key={index}>{item}</option>
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        name="planToAddNewFacilities"
                        checked={this.state.planToAddNewFacilities || ""}
                        onChange={ (e) => this.handleChange(e) }
                        type="checkbox" 
                      />
                      <label className="form-control-label" style={{color: "#32325d"}}>Planning on adding more facilities</label>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              {this.state.planToAddNewFacilities && <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="newFacilitiesCount"
                    >
                      If So, How Many?
                    </label>
                    <Input 
                      id="newFacilitiesCount"
                      name="newFacilitiesCount"
                      value={this.state.newFacilitiesCount || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Number of New Facilities"
                      type="number" 
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="datetoAddNewFacilities"
                    >
                      When?
                    </label>
                    <DatePicker
                      selected={this.state.datetoAddNewFacilities}
                      onChange={(date) => this.handleDateChange("datetoAddNewFacilities", date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                      customInput={<Input disabled={this.props.readonly} />}
                    />
                  </FormGroup>
                </Col>
              </Row>}
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="geographicalAreas"
                    >
                      Geographc Area(s) your Facilities Service
                    </label>
                    <Input
                      id="geographicalAreas"
                      name="geographicalAreas"
                      value={this.state.geographicalAreas || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Geographc Area(s) your Facilities Service"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <p className="mb-0" style={{fontWeight: 500}}>Which products or services are you interested in discussing? Please check all boxes that apply.</p>
              {this.state.equipmentOptions.map((item, index) =>
                <Row key={index}>
                  <Col>
                  {!!item.title && (<label className="pt-3 form-control-label">{item.title}</label>)}
                    <FormGroup check>
                      <Label check>
                        <Input 
                          id={item.key}
                          name={item.key}
                          checked={this.state[item.key] || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                          <label className="form-control-label" style={{color: "#32325d"}}>{item.label}</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <br />
              <label>
                Priority of Interest Regarding Products & Services
              </label>
              <p>
                Of the products and services that you are interested in discussing, what are your top three priorities? Please select your priorities from these drop down menus.
              </p>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="buyerPriority1"
                    >
                      1st Priority *
                    </label>
                    <Input 
                      id="buyerPriority1"
                      name="buyerPriority1"
                      value={this.state.buyerPriority1 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.equipmentOptions.map((item, index) =>
                        <option key={index}>{item.label}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="buyerPriority2"
                    >
                      2nd Priority *
                    </label>
                    <Input 
                      id="buyerPriority2"
                      name="buyerPriority2"
                      value={this.state.buyerPriority2 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.equipmentOptions.map((item, index) =>
                        <option key={index}>{item.label}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="buyerPriority3"
                    >
                      3rd Priority *
                    </label>
                    <Input 
                      id="buyerPriority3"
                      name="buyerPriority3"
                      value={this.state.buyerPriority3 || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.equipmentOptions.map((item, index) =>
                        <option key={index}>{item.label}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <label>
                Optional Information
              </label>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="fiscalYearEnd"
                    >
                      Fiscal Year Ends
                    </label>
                    <Input
                      id="fiscalYearEnd"
                      name="fiscalYearEnd"
                      value={this.state.fiscalYearEnd || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Fiscal Year Ends"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="purchasingTimeFrame"
                    >
                      Purchasing Time Frame
                    </label>
                    <Input 
                      id="purchasingTimeFrame"
                      name="purchasingTimeFrame"
                      value={this.state.purchasingTimeFrame || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="select" 
                      disabled={this.props.readonly}
                    >
                      {this.state.purchasingTimeFrameOptions.map((item, index) =>
                        <option key={index}>{item}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="budget"
                    >
                      Budget
                    </label>
                    <Input
                      id="budget"
                      name="budget"
                      value={this.state.budget || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Budget"
                      type="text"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="comments"
                    >
                      Comments
                    </label>
                    <Input
                      id="comments"
                      name="comments"
                      value={this.state.comments || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Comments"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <div className="ml-1">
                <label
                  className="form-control-label"
                  htmlFor="dietaryRestrictions"
                >
                  Profile Picture
                  <p>
                    Please upload a headshot photo for your Profile. This photo will appear in the Event Booklet  (which is black and white) and distributed to all attendees.
                    Upload a photo in the resolution range of 300 to 600 dpi.
                    Click the profile picture box to upload your image. (jpg or png)
                  </p>
                </label>
                <br/>
                {this.canUploadImage()
                ? (
                  <>
                    <img src={this.state.imageString} alt="Preview" />
                    {!this.props.readonly && <div><Button color="danger" disabled={this.context.loading} onClick={this.props.deletePhoto} className="ml-2">Delete</Button></div>}
                  </>
                  ) : (
                    <>
                      {!this.props.readonly && (<Avatar
                        width={280}
                        height={280}
                        onCrop={this.onCrop}
                        onClose={this.onClose}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        src={this.state.src}
                        exportAsSquare={true}
                        exportSize={280}
                        label="Profile Picture"
                      />)}
                    </>
                  )}
              </div> */}
            </CardBody>
            </>
          )}
          {this.context.user && (
            <>
              <CardHeader style={{ borderBottom: "2px solid red", margin: "0 24px" }}>
                <h1 className="mb-0" style={{color: "red"}}><strong>Admin (Internal Use Only)</strong></h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      style={{ fontSize: 15 }}
                    >
                      <strong>Is this person the representative responsible for receiving appointments on the appointment-matching day?</strong>
                    </label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="primaryRepresentative"
                          checked={this.state.primaryRepresentative || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                          <label className="form-control-label" style={{color: "#32325d"}}>Yes</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="guestOfGuestTotalDue"
                    >
                      Guest of Guest Fees amount Due
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        id="guestOfGuestTotalDue"
                        name="guestOfGuestTotalDue"
                        value={this.state.guestOfGuestTotalDue}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Total Due"
                        type="number" 
                        disabled={this.props.readonly}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="guestOfGuestFeesDetails"
                    >
                      Guest of Guest Fees Details
                    </label>
                    <Input
                      id="guestOfGuestFeesDetails"
                      name="guestOfGuestFeesDetails"
                      value={this.state.guestOfGuestFeesDetails || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Details"
                      maxLength="100"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="specialArrangementsFees"
                    >
                      Special Arrangements Fees & Travel
                    </label>
                    <Input
                      id="specialArrangementsFees"
                      name="specialArrangementsFees"
                      value={this.state.specialArrangementsFees || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Details"
                      maxLength="100"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="nightsPaidByFisa"
                      >
                        Nights Paid By FISA
                      </label>
                      <Input
                        id="nightsPaidByFisa"
                        name="nightsPaidByFisa"
                        value={this.state.nightsPaidByFisa}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Nights"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="nightsPaidByGuest"
                      >
                        Nights Paid By Guest
                      </label>
                      <Input
                        id="nightsPaidByGuest"
                        name="nightsPaidByGuest"
                        value={this.state.nightsPaidByGuest}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Nights"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                    >
                      Needs Ground Transport to Airport?
                    </label>
                    <Row>
                      <FormGroup check className="ml-3">
                        <Label check>
                          <Input
                            name="requiresGroundTransport"
                            checked={this.state.requiresGroundTransport === "YES"}
                            value="YES"
                            onChange={ (e) => this.handleMultipleCheckboxes(e) }
                            type="checkbox" 
                          />
                          <label className="form-control-label" style={{color: "#32325d"}}>Yes</label>
                        </Label>
                      </FormGroup>
                      <FormGroup check className="ml-4">
                        <Label check>
                          <Input
                            name="requiresGroundTransport"
                            checked={this.state.requiresGroundTransport === "NO"}
                            value="NO"
                            onChange={ (e) => this.handleMultipleCheckboxes(e) }
                            type="checkbox" 
                          />
                          <label className="form-control-label" style={{color: "#32325d"}}>No</label>
                        </Label>
                      </FormGroup>
                      <FormGroup check className="ml-4">
                        <Label check>
                          <Input
                            name="requiresGroundTransport"
                            checked={this.state.requiresGroundTransport === "MAYBE"}
                            value="MAYBE"
                            onChange={ (e) => this.handleMultipleCheckboxes(e) }
                            type="checkbox" 
                          />
                          <label className="form-control-label" style={{color: "#32325d"}}>Maybe</label>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                    >
                      Single Room?
                    </label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="singleRoom"
                          checked={this.state.singleRoom || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                          <label className="form-control-label" style={{color: "#32325d"}}>Yes</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                    >
                      Renting a Car?
                    </label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="rentingCar"
                          checked={this.state.rentingCar || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                        />
                          <label className="form-control-label" style={{color: "#32325d"}}>Yes</label>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                    >
                      Rental Car Details
                    </label>
                    <FormGroup>
                      <Input
                        name="rentalCarDetails"
                        value={this.state.rentalCarDetails || ""}
                        onChange={ (e) => this.handleChange(e) }
                        type="textarea" 
                        maxLength="100"
                        placeholder="Details"
                        disabled={this.props.readonly}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="hotelConfirmationNumber"
                      >
                        Hotel Confirmation Number
                      </label>
                      <Input
                        id="hotelConfirmationNumber"
                        name="hotelConfirmationNumber"
                        value={this.state.hotelConfirmationNumber || ""}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Confirmation Number"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </>
          )}
          {!this.props.readonly && <CardFooter>
            <Row>
              <Col />
              <Col lg="auto">
                <Button type="submit" color="dark" disabled={this.context.loading}>Submit</Button>
              </Col>
            </Row>
          </CardFooter>}
        </Form>
      </>
    );
  }
}
export default Elements;
