/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SupplierForm from "components/Forms/SupplierForm"
import { UserContext } from "lib/UserContext";
import AppointmentForm from "components/Forms/AppointmentForm"
import axiosInstance from "api";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class Suppliers extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        delete row.registration.id; // Prevents spread opperator from overriding the id of the supplier
        this.setState({
          selectedItem: {...row, ...row.registration},
          editAppointments: false,
          selectedSupplier: {...row},
        });
        this.getEventAttendeeId(row.registration.email);
      }
    };

    this.state = {
      rowEvents,
      selectedItem: null,
      eventAttendeeId: null,
      error: null,
      confirmEmails: false,
      deletePhoto: false,
      selectedSupplier: null,
    };

    this.onError = this.onError.bind(this);
    this.onReset = this.onReset.bind(this);
    this.toggleEmailModal = this.toggleEmailModal.bind(this);
    this.toggleDeletePhotoModal = this.toggleDeletePhotoModal.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.getEventAttendeeId = this.getEventAttendeeId.bind(this);
    this.toggleDeleteRegistrationModal = this.toggleDeleteRegistrationModal.bind(this);
    this.deleteRegistrant = this.deleteRegistrant.bind(this);
    this.archiveRegistrant = this.archiveRegistrant.bind(this);
  }

  getEventAttendeeId = async (email) => {
    this.context.setState(s => ({...s, loading: true}));
    axiosInstance
      .get(`registration/findEventAttendeeId/admin/${email}/${this.context.activeEvent.id}`)
      .then((resp) => {
        this.setState((s) => ({
          ...s,
          eventAttendeeId: resp.data,
        }));
      })
      .catch(e => this.onError("Something happened when trying to load schedule data."))
      .finally(() => {
        this.context.setState((s) => ({ ...s, loading: false }));
      });
  }
  
  toggleDeleteRegistrationModal = () => {
    const isOpen = this.state.deleteRegistration;
    this.setState({deleteRegistration: !isOpen});
  }
  
  toggleDeletePhotoModal = () => {
    const isOpen = this.state.deletePhoto;
    this.setState({deletePhoto: !isOpen});
  }

  toggleEmailModal = () => {
    const isOpen = this.state.confirmEmails;
    this.setState({confirmEmails: !isOpen});
  }

  printFinalConfirmations = async () => {
    this.context.setState(s => { return { ...s, loading: true }});
    const response = await axiosInstance.post(`/registration/bulk-print/supplier/${this.context.activeEvent.id}`, {}, {responseType: 'blob'});

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.style.display = "none";
    link.href = url;
    link.setAttribute('download', "SupplierFinalConfirmations.pdf");
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);

    this.context.setState(s => { return { ...s, loading: false }});
  }

  deleteRegistrant = async () => {
    try {
      const selectedItem = this.state.selectedItem;
      await axiosInstance.delete(
        `registration/delete-supplier/${selectedItem.personId}/${selectedItem.id}/${selectedItem.registrationId}/${this.context.activeEvent.id}`);
      this.onReset();
    } catch (error) {
      this.onError(error.response?.data || "An error occured");
    }
  }

  archiveRegistrant = async () => {
    try {
      await axiosInstance.post(`registration/toggle-archive-supplier`, {
        eventId: this.context.activeEvent.id,
        registration: this.state.selectedSupplier.registration,
        supplierRegistration: this.state.selectedSupplier,
      });
      this.onReset();
    } catch (error) {
      console.error(error)
      this.onError(error.response?.data || "An error occured");
    }
  }

  deletePhoto = async () => {
    this.context.setState(s => { return { ...s, loading: true }});
    const selectedItem = this.state.selectedItem;
    const selectedSupplier = this.context.suppliers.find(f => f.registrationId === selectedItem.registrationId);
    this.setState({selectedItem: null});
    if (selectedItem.registrationId && selectedSupplier) {
      await axiosInstance.post(`registration/supplier/delete-photo/${selectedItem.registrationId}`);
      this.setState({selectedItem: {...selectedItem, imageString: ""}});
      this.context.setState(s => ({...s, suppliers: s.suppliers.filter(f => f.id !== selectedSupplier.id).concat([{...selectedSupplier, registration: {...selectedSupplier, imageString: ""}}])}));
    }
    this.context.setState(s => ({ ...s, loading: false }));
  }

  sendBulkEmails = async () => {
    try {
      this.context.setState(s => { return { ...s, loading: true }});
      await axiosInstance.post(`/registration/bulk-send/supplier/${this.context.activeEvent.id}`);
    } catch (error) {
      this.onError("Something went wrong. Is the final confirmation template uploaded?")
    } finally {
      this.context.setState(s => { return { ...s, loading: false }});
    }
  }

  sendEmail = async () => {
    try {
      this.context.setState(s => { return { ...s, loading: true }});
      await axiosInstance.post(`/registration/send-email/supplier/${this.state.selectedItem.registrationId}`);
    } catch (error) {
      this.onError("Something went wrong. Is the final confirmation template uploaded?")
    } finally {
      this.context.setState(s => { return { ...s, loading: false }});
    }
  }
  
  onReset = () => {
    this.setState({ error: null, selectedItem: null })
    const activeEvent = this.context.activeEvent;
    this.context.setState(s => ({...s, activeEvent: null}))
    this.context.selectEvent(activeEvent);
  }

  onError = (err) => {
    this.setState({ error: err })
  }

  render() {
    return (
      <>
        <SimpleHeader name="Suppliers" parentName="Home" />
        {this.context.activeEvent && <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              {this.state.selectedItem === null &&
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Suppliers</h3>
                </CardHeader>
                <ToolkitProvider
                  data={this.context.activeEvent ? this.context.suppliers : []}
                  keyField="id"
                  columns={[
                    {
                      headerStyle: { color: "black" },
                      dataField: "registration.firstName",
                      text: "First Name",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "registration.lastName",
                      text: "Last Name",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "registration.title",
                      text: "Job Title",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "registration.organizationName",
                      text: "Company/Organization",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "registration.email",
                      text: "Email",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "registration.webUrl",
                      text: "Website",
                      sort: true
                    },
                    {
                      headerStyle: { color: "black" },
                      dataField: "archived",
                      text: "Canceled",
                    }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter w-100 px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                        <Button color="dark" className="float-right btn-sm mb-2" onClick={() => this.setState({selectedItem: {}})} disabled={!this.context.activeEvent}>Create Supplier</Button>
                        <Button color="dark" className="float-right btn-sm mr-2 mb-2" onClick={this.printFinalConfirmations}>Print Final Confirmations</Button>
                        {this.context.activeEvent && <Button color="dark" className="float-right btn-sm mr-2 mb-2" onClick={this.toggleEmailModal} disabled={!this.context.activeEvent.supplierConfirmationEmailTemplate}>Send Bulk Email Confirmations</Button>}
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        rowEvents={this.state.rowEvents}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>}
              {this.state.selectedItem !== null && (
              <Card>
                <CardHeader>
                  <Button close onClick={() => this.setState({selectedItem: null})}/>
                  <Row>
                    <Col>
                    {this.state.selectedItem.id && <>
                      <h3 className="mb-0">{this.state.selectedItem.firstName} {this.state.selectedItem.lastName}, {this.state.selectedItem.organizationName} </h3>
                    </>}
                    {!this.state.selectedItem.id && <>
                      <h3 className="mb-0">Create New Supplier </h3>
                    </>}
                    </Col>
                    
                    {this.state.selectedItem.id && this.context.activeEvent && <Col lg="auto">
                      <Button color="dark" outline onClick={this.sendEmail} disabled={!this.context.activeEvent.buyerFinalConfirmationEmailTemplate}>Send Final Confirmation</Button>
                      <Button color="dark" outline onClick={() => { this.setState({editAppointments: !this.state.editAppointments})}}>{this.state.editAppointments ? "Go Back" : "Edit Appointments"}</Button>
                      <Button color="danger" onClick={this.toggleDeleteRegistrationModal}>Delete Supplier</Button>
                      <Button color={!this.state.selectedItem.archived ? "danger" : "success"} onClick={this.archiveRegistrant}>{!this.state.selectedItem.archived ? "Cancel Registration" : "Resume Registration"}</Button>
                    </Col>}
                  </Row>
                </CardHeader>
                {!this.state.editAppointments && <SupplierForm
                  data={{...this.state.selectedItem, timeZone: this.context.activeEvent.timeZone}}
                  eventId={this.context.activeEvent.id}
                  onError={this.onError}
                  onSuccess={this.onReset}
                  deletePhoto={this.toggleDeletePhotoModal}
                />}
                {this.state.editAppointments && <AppointmentForm event={this.context.activeEvent} eventAttendeeId={this.state.eventAttendeeId} email={this.state.selectedItem.email} onError={this.onError} />}
              </Card>)}
            </div>
          </Row>
          <Modal isOpen={this.state.deleteRegistration}>
            <ModalHeader>Delete Supplier?</ModalHeader>
            <ModalBody>
              Are you sure that you want to delete this supplier?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => {this.deleteRegistrant(); this.toggleDeleteRegistrationModal()}}>Yes</Button>
              <Button color="secondary" onClick={this.toggleDeleteRegistrationModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.deletePhoto}>
            <ModalHeader>Delete Photo?</ModalHeader>
            <ModalBody>
              Are you sure that you want to delete this photo?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => {this.deletePhoto(); this.toggleDeletePhotoModal()}}>Yes</Button>
              <Button color="secondary" onClick={this.toggleDeletePhotoModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.confirmEmails}>
            <ModalHeader>Send Bulk Final Confirmation Emails?</ModalHeader>
            <ModalBody>
              Are you sure that you want to send bulk final confirmation emails?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => {this.sendBulkEmails(); this.toggleEmailModal()}}>Yes</Button>
              <Button color="secondary" onClick={this.toggleEmailModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={!!this.state.error}>
            <ModalHeader toggle={() => { this.setState({error: null}) }}>Please Note</ModalHeader>
            <ModalBody>
              <span dangerouslySetInnerHTML={{__html: this.state.error}}></span>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => {this.setState({error: null})}}>OK</Button>
            </ModalFooter>
          </Modal>
        </Container>}
        {!this.context.activeEvent && <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  No Event Selected
                </CardHeader>
              </Card>
            </div>
          </Row>
        </Container>}
      </>
    );
  }
}

export default Suppliers;
