/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
// core components
import { UserContext } from "../../lib/UserContext";
import axiosInstance from "api";
import moment from "moment-timezone";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import BuyerForm from "./BuyerForm";
import Logo from "../../assets/img/brand/fisa-logo.png";
class Timeline extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      timeZone: props.event?.timeZone,
      appointments: [],
      eventAttendees: [],
      eventAppointmentTimes: [],
      email: props.email || "",
      firstName: "",
      lastName: "",
      emailScheduleOpen: false,
    };

    moment.tz.setDefault(this.state.timeZone);
  }

  async componentDidMount() {
    await this.getEventAttendee();
    await this.getAttendees();
  }

  getEventAttendee = async () => {
    this.context.setState((s) => ({ ...s, loading: true }));
    const resp = await axiosInstance.get(`registration/getEventAttendee/${this.props.eventAttendeeId}`);
    this.setState((s) => ({
      ...s,
      eventAttendeeId: resp.data ? resp.data.id : null,
      firstName: resp.data ? resp.data.person.firstName : null,
      lastName: resp.data ? resp.data.person.lastName : null,
      badgeName: resp.data ? resp.data.person.badgeName : null,
      appointments: resp.data ? resp.data.eventAppointments || [] : [],
      email: `${this.props.email}, ${resp.data?.assistantEmail ?? ''}`,
    }));
    this.context.setState((s) => ({ ...s, loading: false }));
  }

  getAttendees = async () => {
    this.context.setState((s) => ({ ...s, loading: true }));
    const eventAttendees = await axiosInstance.get(`registration/eventAttendees/${this.props.event.id}`);
    this.setState((s) => ({...s, eventAttendees: eventAttendees.data || []}));
    this.context.setState((s) => ({ ...s, loading: false }));
  }

  getAvailableBuyers = async (timeSlotId) => {
    if (timeSlotId) {
      this.context.setState((s) => ({ ...s, loading: true }));
      const buyers = await axiosInstance.get(`registration/availableEventAttendees/${this.props.event.id}/${this.state.eventAttendeeId}/${timeSlotId}`);
      this.setState((s) => ({...s, buyers: buyers.data || []}));
      this.context.setState((s) => ({ ...s, loading: false }));
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSaveAllChanges = async (event) => {
    event.preventDefault();

    const lastAppointment = this.state.appointments.at(-1);
    const endpoint =
      `registration/insertEventAppointments/${this.state.eventAttendeeId}` +
      `/${lastAppointment.buyerAttendeeId}/${lastAppointment.eventAppointmentTimeId}`;

    this.context.setState((s) => ({ ...s, loading: true }));
    axiosInstance
      .post(endpoint)
      .then(() => this.setState(s => ({...s, openBlockModal: false})))
      .catch(e => {
        this.props.onError(e.response.data ?? "An error occurred");
        const appointments = this.state.appointments;
        appointments.pop();
    
        this.setState((s) => ({
          ...s,
          appointments,
          buyerAttendeeId: null,
          buyerEventAttendee: null,
          appointmentId: null,
          openTimeModal: false,
        }));
      })
      .finally(() => this.context.setState((s) => ({ ...s, loading: false })))
  };

  handleSaveTimeSlot(e, id) {
    e.preventDefault();

    if (
      !this.state.eventAppointmentTimeId ||
      this.state.appointments.length >= this.props.event.maxSupplierAppointments
    ) {
      return;
    }

    const payload = {
      supplierAttendeeId: this.props.eventAttendeeId,
      buyerAttendeeId:
        id ||
        this.state.eventAttendees.filter((item) => item.buyer)[0].id,
      eventAppointmentTimeId: this.state.eventAppointmentTimeId,
    };

    const appointments = this.state.appointments;

    appointments.push(payload);
    this.setState((s) => ({
      ...s,
      appointments,
    }));

    this.handleSaveAllChanges(e);
  }

  handleDeleteTimeSlot = (e) => {
    e.preventDefault();

    const appointment = {...this.state.appointments[this.state.appointmentId]};
    const endpoint =
      `registration/deleteEventAppointment/${this.state.eventAttendeeId}` +
      `/${appointment.buyerAttendeeId}/${appointment.eventAppointmentTimeId}`;

    this.context.setState((s) => ({ ...s, loading: true }));
    axiosInstance
      .delete(endpoint)
      .then(() => {
        this.setState(s => {
          const appointments = [...s.appointments];
          appointments.splice(this.state.appointmentId, 1);

          return {
            appointments: appointments,
            buyerAttendeeId: null,
            buyerEventAttendee: null,
            appointmentId: null,
            openTimeModal: false,
            openBlockModal: false
          }
        })
      })
      .catch(e => {
        this.props.onError(e.response.data ?? "An error occurred");
        this.setState({
          buyerAttendeeId: null,
          buyerEventAttendee: null,
          appointmentId: null,
          openTimeModal: false,
        });
      })
      .finally(() => this.context.setState((s) => ({ ...s, loading: false })))
  };

  handleSelectAppointmentsAvailable = async (event) => {
    if (this.props.event.maxSupplierAppointments - this.state.appointments.length === 0) {
      this.props.onError("Your Individual appointment maximum is reached. Either edit appointments or click on email and download your completed schedule at the bottom.");
      return
    }

    if (event.eventAppointmentTimes.length <= 0) {
      return;
    }

    const takenAppointments = (this.state.appointments || []).map(
      (item) => parseInt(item.eventAppointmentTimeId),
    );
    const eventAppointmentTimes = event.eventAppointmentTimes.filter(
      (item) => takenAppointments.indexOf(item.id) < 0 && !item.notAvailable
    );

    this.setState({
      appointmentId: null,
      buyerAttendeeId: event.buyerAttendeeId,
      buyerEventAttendee: null,
      startTime: event.start,
      endTime: event.end,
      eventAppointmentTimes:
        event.eventAppointmentTimes.length > 0 ? eventAppointmentTimes : null,
      eventAppointmentTimeId:
        eventAppointmentTimes.length > 0 ? eventAppointmentTimes[0].id : null,
      openBlockModal: true,
    });

    await this.getAvailableBuyers(eventAppointmentTimes.length > 0 ? eventAppointmentTimes[0].id : null)
  };

  handleSelectTimeSlot = (event) => {
    this.setState({
      appointmentId: event.id,
      buyerAttendeeId: event.buyerAttendeeId,
      buyerEventAttendee: null,
      startTime: event.start,
      endTime: event.end,
      eventAppointmentTimes: [],
      eventAppointmentTimeId: null,
      openTimeModal: true,
    });
  };

  Event = ({ event }) => {
    return (
      <span>
        <strong>{event.title}</strong>
        {event.desc && ":  " + event.desc}
      </span>
    );
  };

  EventAgenda = ({ event }) => {
    return (
      <span className="w-100">
        {!event.appointment && (
          <span style={{ color: "default" }}>
            {event.eventAppointmentTimes &&
              event.eventAppointmentTimes.length > 0 && (
                <>
                  <i className="fas fa-calendar-plus mr-2" /> {event.title}
                  {(event.eventAppointmentTimes.filter(f => !f.notAvailable).length > 0) && (
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      className="py--1 ml-3"
                      onClick={() => {
                        this.handleSelectAppointmentsAvailable(event);
                      }}
                    >
                      <span style={{ fontWeight: "bolder" }}>{event.eventAppointmentTimes.filter(f => !f.notAvailable).length} Appointment(s) Available</span>
                    </Button>
                  )}
                </>
              )}
            {(!event.eventAppointmentTimes ||
              event.eventAppointmentTimes.length <= 0) && (
              <>
                <span className="text-muted">
                  <i className="fas fa-calendar mr-2" /> {event.title}{" "}
                </span>
              </>
            )}
          </span>
        )}
        {event.appointment && (
          <em style={{ color: "red" }}>
            <i className="fas fa-user mr-2" />
            {event.title}{" "}
            <Button
              onClick={() => {
                this.handleSelectTimeSlot(event);
              }}
              outline
              color="default"
              size="sm"
              className="py--1 ml-3"
            >
              Edit
            </Button>
          </em>
        )}
      </span>
    );
  };

  getEventAttendeeName = (id) => {
    if (!id) {
      return "";
    }
    const eventAttendee = this.state.eventAttendees.find(
      (item) => item.id.toString() === id.toString(),
    );
    return eventAttendee
      ? eventAttendee.person.lastName +
          ", " +
          eventAttendee.person.firstName +
          " (" +
          eventAttendee.person.organizationName +
          ")"
      : "";
  };

  getEventAttendeeFullName = (id) => {
    if (!id) {
      return "";
    }
    const eventAttendee = this.state.eventAttendees.find(
      (item) => item.id.toString() === id.toString(),
    );
    return eventAttendee
      ? eventAttendee.person.lastName + ", " + eventAttendee.person.firstName
      : "";
  };

  getEventAttendeeCompany = (id) => {
    if (!id) {
      return "";
    }
    const eventAttendee = this.state.eventAttendees.find(
      (item) => item.id.toString() === id.toString(),
    );
    return eventAttendee ? eventAttendee.person.organizationName : "";
  };

  sendEmail = async (e, appointments) => {
    e.preventDefault();
    for (const appointment of appointments) {
      appointment.start = moment(appointment.start).format("hh:mm a").toUpperCase();
      appointment.end = moment(appointment.end).format("hh:mm a").toUpperCase();
    }

    await this.context.setState(s => ({...s, loading: true}));
    await axiosInstance.post("/registration/email-schedule", {
      eventAttendee: this.props.eventAttendeeId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      selectedEvent: this.props.event,
      appointments: appointments,
    });
    this.setState({ emailScheduleOpen: false });
    await this.context.setState(s => ({...s, loading: false}));
  }

  downloadSchedule = async (appointments) => {
    for (const appointment of appointments) {
      appointment.start = moment(appointment.start).format("hh:mm a").toUpperCase();
      appointment.end = moment(appointment.end).format("hh:mm a").toUpperCase();
    }

    await this.context.setState(s => ({...s, loading: true}));
    const response = await axiosInstance.post("/registration/download-schedule", {
        eventAttendee: this.props.eventAttendeeId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        selectedEvent: this.props.event,
        appointments: appointments,
      },
      {
        responseType: "blob"
      });

    await this.context.setState(s => ({...s, loading: false}));
    const {data} = response;
    const blob = new Blob([data], {type: "application/pdf"})
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'FISA_EventAppointmentSchedule.pdf';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  render() {
    const takenAppointments = (this.state.appointments || []).map(
      (item) => parseInt(item.eventAppointmentTimeId),
    );
    const appointmentSlots = [].concat.apply(
      [],
      (this.props.event ? this.props.event.eventAppointmentBlocks : []).map(
        (item) => item.eventAppointmentTimes,
      ),
    );

    const eventAppointmentBlocks = (this.props.event
      ? this.props.event.eventAppointmentBlocks
      : []
    ).map((item, index) => ({
      id: index,
      appointment: false,
      title: item.blockName,
      start: new Date(item.blockStartDateTime),
      end: new Date(item.blockEndDateTime),
      eventAppointmentTimes: item.eventAppointmentTimes.filter(
        (item) => takenAppointments.indexOf(item.id) < 0,
      ),
    }));

    const appointments = (this.state.appointments || []).map((item, index) => {
      const slot = appointmentSlots.find(
        (x) => x.id.toString() === item.eventAppointmentTimeId.toString(),
      );

      return {
        id: index,
        appointment: true,
        title: this.getEventAttendeeName(item.buyerAttendeeId),
        eventAppointmentBlockId: slot.eventAppointmentBlockId,
        start: new Date(slot.startTime),
        end: new Date(slot.endTime),
        buyerAttendeeId: item.buyerAttendeeId,
      };
    });

    const events = [...eventAppointmentBlocks, ...appointments];

    return (
      <>
        {this.props.eventAttendeeId && (
          <div ref={(el) => (this.componentRef = el)}>
            <CardHeader className="bg-transparent pb-0">
              <Row className="justify-content-center mb-3">
                <img
                  src={Logo}
                  className="img-fluid"
                  alt="logo"
                  id="print-logo"
                />
              </Row>
              <Row>
                <Col>
                  <h2 className="mb-0">{this.state.event?.name}</h2>
                  <h3 className="mb-0">Click the red boxes below (Appointments Available) to schedule appointments</h3>
                  <h3 className="mb-0">{this.state.firstName} {this.state.lastName}</h3>
                </Col>
                <Col xs="auto">
                  <Row>
                    <Col className="w-100">
                      <h4 className="mb-0" id="appointment-slots-left">Your Appointment Slots Left</h4>
                      <p>{this.state.appointments.length} currently selected</p>
                    </Col>
                    <Col sm="auto" className="pl-0">
                      <Badge
                        id="badge-items-left"
                        pill
                        className="bg-danger text-white px-3 "
                        style={{ fontSize: 30 }}
                      >
                        {this.props.event.maxSupplierAppointments -
                          this.state.appointments.length}
                      </Badge>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Calendar
                selectable
                toolbar={false}
                localizer={momentLocalizer(moment)}
                events={events}
                views={[Views.AGENDA]}
                defaultView={Views.AGENDA}
                scrollToTime={
                  this.props.event
                    ? new Date(moment(this.props.event.startDate).startOf("week"))
                    : new Date()
                }
                defaultDate={
                  this.props.event
                    ? new Date(moment(this.props.event.startDate).startOf("week"))
                    : new Date()
                }
                components={{
                  event: this.Event,
                  agenda: {
                    event: this.EventAgenda,
                  },
                }}
              />
            </CardBody>
            <CardFooter>
              <Row>
                {this.props.signOut && <Button color="dark" disabled={this.context.loading} onClick={this.props.signOut}>Sign Out</Button>}
                <Col />
                <Button color="dark" disabled={this.context.loading} onClick={() => this.setState({emailScheduleOpen: true})}>Email Schedule</Button>
                <Button
                  onClick={() => this.downloadSchedule(appointments)}
                  color="dark"
                  disabled={this.context.loading}
                >Download Schedule</Button>
              </Row>
            </CardFooter>
          </div>
        )}
        <Modal size="lg" isOpen={this.state.emailScheduleOpen}>
          <Form className="form" onSubmit={e => this.sendEmail(e, appointments)}>
            <ModalHeader toggle={() => this.setState({ emailScheduleOpen: false })}>
              Email Schedule
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="email"
                  >
                    Emails (separated by commas) *
                  </label>
                  <Input
                    id="email"
                    name="email"
                    value={this.state.email || ""}
                    onChange={ (e) => this.handleInputChange(e) }
                    placeholder="Email"
                    type="text"
                    required
                  />
                </FormGroup>
            </ModalBody>
            <CardFooter>
              <Row>
                <Col />
                <Button
                  color="dark"
                  disabled={this.context.loading}
                  type="submit"
                >
                  Send
                </Button>
              </Row>
            </CardFooter>
          </Form>
        </Modal>
        <Modal size="lg" isOpen={!!this.state.openBlockModal}>
          <div className="form">
            <ModalHeader
              toggle={() => {
                this.setState({ openBlockModal: false });
              }}
            >
              {new Date(this.state.startTime).toLocaleDateString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} (
              {new Date(this.state.startTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} -{" "}
              {new Date(this.state.endTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})}) Appointment
              Block
            </ModalHeader>
            <ModalBody>
              <label>First, select a time slot convenient for you.</label>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">Time Slot *</label>
                    <Input
                      id="eventAppointmentTimeId"
                      name="eventAppointmentTimeId"
                      value={this.state.eventAppointmentTimeId || ""}
                      onChange={(e) => {
                        this.handleInputChange(e);
                        this.getAvailableBuyers(e.target.value);
                      }}
                      type="select"
                      required
                    >
                      {this.state.eventAppointmentTimes.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {new Date(item.startTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} -{" "}
                            {new Date(item.endTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <label>Next, select a Buyer that you would like an appointment with.</label>
              <br />
              <label className="form-control-label">Buyer *</label>
              <ListGroup>
                {this.state.buyers && this
                  .state
                  .buyers
                  .sort((a, b) => a.person.lastName.localeCompare(b.person.lastName))
                  .map((item, index) => (
                  <ListGroupItem
                    className={
                      this.state.buyerAttendeeId !== item.id
                        ? ""
                        : "bg-secondary"
                    }
                    key={index}
                  >
                    <Row>
                      <Col>
                        <label className="form-control-label">Name</label>
                        <br />
                        <p>
                          {item.person.lastName}, {item.person.firstName}
                        </p>
                      </Col>
                      <Col>
                        <label className="form-control-label">Company</label>
                        <br />
                        <p>{item.person.organizationName}</p>
                      </Col>
                      <Col lg="auto">
                        <div className="pt-3">
                          {this.state.buyerAttendeeId !== item.id && (
                            <Button
                              outline
                              color="default"
                              onClick={(e) => {
                                this.setState({ buyerAttendeeId: item.id });
                                this.handleSaveTimeSlot(e, item.id);
                              }}
                              disabled={this.context.loading}
                            >
                              Select Buyer
                            </Button>
                          )}
                          <Button
                            outline
                            color="default"
                            onClick={() => {
                              this.setState({
                                buyerEventAttendee: item,
                                appointmentId: null,
                              });
                              setTimeout(() => {
                                document.getElementById('facility-header').scrollIntoView({ behavior: 'smooth' });
                              }, 1300);
                            }}
                            disabled={this.context.loading}
                          >
                            View Profile
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </ModalBody>
          </div>
        </Modal>
        {this.state.openTimeModal && this.state.buyerAttendeeId && (
          <Modal size="lg" isOpen={true}>
            <div className="form">
              <ModalHeader
                toggle={() => {
                  this.setState({ openTimeModal: false });
                }}
              >
                {this.getEventAttendeeName(this.state.buyerAttendeeId)}
                <p>
                  {new Date(this.state.startTime).toLocaleDateString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} (
                  {new Date(this.state.startTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} -{" "}
                  {new Date(this.state.endTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})})
                </p>
              </ModalHeader>
              <ModalBody>
                <ListGroup>
                  <ListGroupItem className="bg-secondary">
                    <Row>
                      <Col>
                        <label className="form-control-label">Name</label>
                        <br />
                        <p>
                          {this.getEventAttendeeFullName(
                            this.state.buyerAttendeeId,
                          )}
                        </p>
                      </Col>
                      <Col>
                        <label className="form-control-label">Company</label>
                        <br />
                        <p>
                          {this.getEventAttendeeCompany(
                            this.state.buyerAttendeeId,
                          )}
                        </p>
                      </Col>
                      <Col lg="auto">
                        <div className="pt-3">
                          <Button
                            outline
                            color="default"
                            onClick={() => {
                              this.setState({
                                buyerEventAttendee: this.state.buyerAttendeeId
                                  ? this.state.eventAttendees.find(
                                      (item) =>
                                        this.state.buyerAttendeeId.toString() ===
                                        item.id.toString(),
                                    )
                                  : null,
                              });
                            }}
                          >
                            View Profile
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </ModalBody>
              <CardFooter>
                <Row>
                  {this.state.appointmentId != null && (
                    <Button
                      onClick={e => this.handleDeleteTimeSlot(e)}
                      color="danger"
                      disabled={this.context.loading}
                    >
                      Delete
                    </Button>
                  )}
                  <Col />
                  <Button
                    color="dark"
                    disabled={this.context.loading}
                    onClick={() => {
                      this.setState({ openTimeModal: false });
                    }}
                  >
                    Done
                  </Button>
                </Row>
              </CardFooter>
            </div>
          </Modal>
        )}
        {this.state.buyerEventAttendee && (
          <Modal size="lg" isOpen={true}>
            <div className="form">
              <ModalHeader
                toggle={() => {
                  this.setState({ buyerEventAttendee: null });
                }}
              >
                {this.state.buyerEventAttendee.person.lastName},{" "}
                {this.state.buyerEventAttendee.person.firstName} (
                {this.state.buyerEventAttendee.person.organizationName})
                {!!this.state.appointmentId && (
                  <p>
                    {new Date(this.state.startTime).toLocaleDateString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} (
                    {new Date(this.state.startTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})} -{" "}
                    {new Date(this.state.endTime).toLocaleTimeString("en-US", {timeZone: this.state.timeZone, hour: '2-digit', minute:'2-digit'})})
                  </p>
                )}
              </ModalHeader>
              <ModalBody>
                <BuyerForm
                  data={{
                    ...this.state.buyerEventAttendee.registration
                      .buyerRegistration,
                    ...this.state.buyerEventAttendee.registration,
                  }}
                  ymca={this.props.event.ymca}
                  eventId={this.props.event.id}
                  readonly
                  hidePersonalInfo
                />
              </ModalBody>
              <CardFooter>
                <Row>
                  <Col />
                  <Button
                    color="dark"
                    disabled={this.context.loading}
                    onClick={() => {
                      this.setState({ buyerEventAttendee: null });
                    }}
                  >
                    Done
                  </Button>
                </Row>
              </CardFooter>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default Timeline;
