import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import axiosInstance from "api";

import LoadingOverlay from 'react-loading-overlay';

// Context lets us pass a value deep into the component tree
// without explicitly threading it through every component.
// Create a context for the current authenticated user.
export const UserContext = React.createContext();
UserContext.displayName = "UserContext";

export const UserContextProvider = ({ children }) => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    user: null,
    buyers: [],
    suppliers: [],
    activeEvent: null,
    activeTenant: null,
    tenants: [],
    events: [],
    selectTenant: selectTenant,
    selectEvent: selectEvent,
    setState: setStateData,
  });

  function selectTenant (tenant) {
    localStorage.setItem('tenantId', tenant.id)
    setState(s => { return { ...s, activeTenant: {...tenant, events: tenant.events || []} }})
  }

  function selectEvent (event) {
    localStorage.setItem('eventId', event.id)
    setState(s => { return { ...s, activeEvent: event }})
  }

  function setStateData(data) {
    setState(data);
  }

  // runs on first render
  useEffect(() => {
    if (state.loading) return;
    setState((s) => ({ ...s, loading: true }));

    // Check if user is logged in.
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setState((s) => ({ ...s, user, setState: setState, loading: false, }));
      })
      .catch((error) => {
        setState((s) => ({ ...s, error, loading: false }));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // runs on first render
  useEffect(() => {
    if(!state.user) {
      return
    }
    
    axiosInstance.get('user/tenants').then(resp => {
      if (resp.data.length <= 0) {
        return;
      }

      var activeTenantId = localStorage.getItem('tenantId');
      if (!activeTenantId) {
        activeTenantId = resp.data[0].id
      }

      const activeTenant = resp.data.find(x => x.id.toString() === activeTenantId.toString())
      localStorage.setItem('tenantId', activeTenantId)
      
      setState((s) => ({ 
        ...s, 
        tenants: resp.data, 
        activeTenant
      }));
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  // runs after active tenant is updated
  useEffect(() => {
    if(!state.activeTenant) {
      return;
    }

    const prevEventId = localStorage.getItem('eventId');

    setState(s => ({ ...s, loading: true }))
    axiosInstance.get('/registration/events')
      .then(resp => {
        const activeEvent = prevEventId ? resp.data.find(x => x.id.toString() === prevEventId.toString()) : null;
        setState(s => ({ ...s, activeEvent, events: resp.data }))
      })
      .finally(() => {
        setState(s => ({ ...s, loading: false }))
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeTenant]);

  useEffect(() => {
    if (!state.activeTenant || !state.activeEvent) {
      return;
    }

    setState(s => ({ ...s, loading: true }))
    axiosInstance.get('/registration/buyers/' + state.activeEvent.id)
      .then(resp => {
        setState(s => ({
          ...s,
          buyers: resp.data || []
        }))
      })
      .finally(() => {
        setState(s => ({ ...s, loading: false }))
      })

    setState(s => ({ ...s, loading: true }))
    axiosInstance.get('/registration/suppliers/' + state.activeEvent.id)
      .then(resp => {
        setState(s => ({
          ...s,
          suppliers: resp.data || []
        }))
      })
      .finally(() => {
        setState(s => ({ ...s, loading: false }))
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeEvent]);

  const { Provider } = UserContext;
  return (
    <Provider value={state}>
      <LoadingOverlay
        active={state.loading} 
        spinner
        styles={{
          wrapper: {minHeight: '100%'}
        }}
      >
        {children}
      </LoadingOverlay>
    </Provider>);
};
