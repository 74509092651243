/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDatetime from "react-datetime";
import DatePicker from "react-datepicker";
import "../../assets/css/datepicker.css";
// reactstrap components
import {
  CardBody,
  Form,
  FormGroup,
  Button,
  Input,
  Label,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
// core components
import CardFooter from "reactstrap/lib/CardFooter";
import { UserContext } from "lib/UserContext"
import axiosInstance from "api";
import moment from 'moment-timezone'
import ImageUploader from 'react-images-upload';
import 'react-big-calendar/lib/css/react-big-calendar.css'

class Elements extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.data,
      startDate: this.props.data.startDate ? new Date(this.props.data.startDate) : null,
      endDate: this.props.data.endDate ? new Date(this.props.data.endDate) : null,
      appointmentBlocks: this.props.data ? (this.props.data.eventAppointmentBlocks || []) : [],
      eventAppointmentTimes: [],
      timeZones: [
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Los_Angeles",
      ],
      activeIndex: 0,
      animating: false,
      stopAppointmentsOpen: false,
      timeZone: props.data.timeZone ?? "America/New_York",
      appointmentsSchedulingStopped: props.data.appointmentsSchedulingStopped ? props.data.appointmentsSchedulingStopped : null,
      attachments: this.props.data.attachments ?? {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.toggleSchedulingStopped = this.toggleSchedulingStopped.bind(this);
  }

  componentDidMount() {
    moment.tz.setDefault(this.state.timeZone)
  }
  componentDidUpdate() {
    moment.tz.setDefault(this.state.timeZone)
  }

  next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === Object.keys(this.state.attachments).length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? Object.keys(this.state.attachments).length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({activeIndex: newIndex});
  }

  toggleSchedulingStopped = async () => {
    try {
      this.context.setState((s) => ({ ...s, loading: true }));
      await axiosInstance.post(`registration/toggleAppointmentScheduling/${this.state.id}`);
      this.props.onSuccess();
    } catch (error) {
      console.error(error);
      this.props.onError("There was an error with the request. Please refresh and try again.");
    } finally {
      this.context.setState((s) => ({ ...s, loading: false }));
    }
  }

  saveEventImage = async (event, index) => {
    try {
      if (event && event.length > 0) {
        this.context.setState(s => { return { ...s, loading: true }});
        const value = event[0];
        const formData = new FormData();
        formData.append('file', value);
        const { data } = await axiosInstance.post(`/registration/insert-event-image/${this.state.id}/${index}`, formData);
        this.setState({attachments: data});
        this.context.setState(s => ({...s, events: s.events.filter(f => f.id !== this.state.id).concat([{...this.state}])}));
      }
    } catch (error) {
      this.props.onError("There was an error with the request. Please check the fields and try again.");
    } finally {
      this.context.setState(s => { return { ...s, loading: false }});
    }
  }

  deleteEventImage = async (index) => {
    try {
      this.context.setState(s => { return { ...s, loading: true }});
      const { data } = await axiosInstance.post(`/registration/delete-event-image/${this.state.id}/${index}`);
      this.setState({attachments: data});
      this.context.setState(s => ({...s, events: s.events.filter(f => f.id !== this.state.id).concat([{...this.state}])}));
    } catch (error) {
      this.props.onError("There was an error with the request. Please check the fields and try again.");
    } finally {
      this.context.setState(s => { return { ...s, loading: false }});
    }
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [ name ]: value,
    });
  }

  handleDateChange = async (name, value) => {
    await this.setState({
      [ name ]: value,
    });
  }

  handleTimeChange = async (name, value) => {
    const time = new Date(this.state.currentDay)
    time.setHours(new Date(value).getHours())
    time.setMinutes(new Date(value).getMinutes())
    await this.setState({
      [ name ]: time,
    });
  }

  submitForm(e) {
    e.preventDefault();

    const payload = {
      id: this.state.id,
      name: this.state.name,
      ymca: this.state.ymca || false,
      practiceEvent: this.state.practiceEvent || false,
      nextEvent: this.state.nextEvent || false,
      registrationsAvailable: this.state.registrationsAvailable || false,
      venue: this.state.venue,
      registrationBanner1: this.state.registrationBanner1,
      registrationBanner2: this.state.registrationBanner2,
      registrationBanner3: this.state.registrationBanner3,
      maxSupplierAppointments: this.state.maxSupplierAppointments,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      timeZone: this.state.timeZone,
      buyerRegistrationEmailTemplate: this.state.buyerRegistrationEmailTemplate,
      buyerFinalConfirmationEmailTemplate: this.state.buyerFinalConfirmationEmailTemplate,
      supplierRegistrationEmailTemplate: this.state.supplierRegistrationEmailTemplate,
      supplierConfirmationEmailTemplate: this.state.supplierConfirmationEmailTemplate,
    }

    this.context.setState(s => { return { ...s, loading: true }});
    axiosInstance.post("/registration/insertEvent", payload)
      .then(async () => {
        this.props.onSuccess();
      })
      .catch(err => {
        this.props.onError("There was an error with the request. Please check the fields and try again.");
      })
      .finally(() => {
        this.context.setState(s => { return { ...s, loading: false }});
      })
  }

  submitBlockForm(e) {
    e.preventDefault();

    const payload = {
      eventId: this.state.id,
      blockName: this.state.blockName,
      blockStartDateTime: this.state.blockStartDateTime,
      blockEndDateTime: this.state.blockEndDateTime,
      eventAppointmentTimes: this.state.eventAppointmentTimes,
    }

    const foundAppointmentBlock = this.state.appointmentBlocks[this.state.blockId];
    if (foundAppointmentBlock) {
      payload.id = foundAppointmentBlock.id;
    }

    this.context.setState(s => { return { ...s, loading: true }});
    axiosInstance.post(`/registration/insertEventBlocks`, payload)
      .then(resp => {
        this.props.onSuccess();
      })
      .catch(err => {
        this.props.onError("Admins are not able to add appointment slots after someone is booked");
      })
      .finally(() => {
        this.context.setState(s => { return { ...s, loading: false }});
      });
  }

  handleSelectEvent = ({ id }) => {
    this.setState(s => ({
      ...s,
      blockId: id
    }))
    this.selectAppointmentBlock(this.state.appointmentBlocks[id]);
  }

  handleSelectSlot = ({ start, end }) => {
    if(this.props.readonly) {
      return;
    }

    this.setState(s => ({
      ...s,
      blockId: null
    }))
    this.selectAppointmentBlock({ 
      currentDay: new Date(start),
      blockStartDateTime: start, 
      blockEndDateTime: end 
    });
  }

  handleDeleteAppointment = () => {
    const appointmentBlocks = this.state.appointmentBlocks;
    const foundAppointment = appointmentBlocks[this.state.blockId];

    if (foundAppointment.id) {
      this.context.setState(s => { return { ...s, loading: true }});
      axiosInstance.post(`registration/deleteEventBlock`, foundAppointment)
        .then(res => {
          appointmentBlocks.splice(this.state.blockId, 1);

          this.setState(s => ({
            ...s,
            appointmentBlocks,
            openBlockModal: false,
            blockId: null
          }))
        })
        .catch(err => {
          if (err.response) {
            this.props.onError(err.response.data)
          } else {
            this.props.onError("Something went wrong trying to insert data")
          }
        })
        .finally(() => {
          this.context.setState(s => { return { ...s, loading: false }});
        });
    } else {
      appointmentBlocks.splice(this.state.blockId, 1);

      this.setState(s => ({
        ...s,
        appointmentBlocks,
        openBlockModal: false,
        blockId: null
      }))
    }
  }

  selectAppointmentBlock(item) {
    this.setState(s => ({
      ...s,
      blockName: item.blockName,
      currentDay: new Date(item.blockStartDateTime),
      blockStartDateTime: item.blockStartDateTime,
      blockEndDateTime: item.blockEndDateTime,
      eventAppointmentTimes: item.eventAppointmentTimes || [],
      openBlockModal: true
    }))
  }

  handleTimeSlotDateChange = async (name, index, value) => {
    const { eventAppointmentTimes } = this.state;
    const time = new Date(this.state.currentDay)
    time.setHours(new Date(value).getHours())
    time.setMinutes(new Date(value).getMinutes())
    eventAppointmentTimes[index][name] = time;
    await this.setState({
      eventAppointmentTimes
    });
  }

  handleTimeSlotNotAvailableChange = async (index, value) => {
    const { eventAppointmentTimes } = this.state;
    eventAppointmentTimes[index]["notAvailable"] = value;
    await this.setState({
      eventAppointmentTimes
    });
  }

  addAppointmentTimeSlot = () => {
    const { eventAppointmentTimes, blockStartDateTime, blockEndDateTime } = this.state;

    eventAppointmentTimes.push({
      startTime: blockStartDateTime,
      endTime: blockEndDateTime
    })

    this.setState(s => ({
      ...s,
      eventAppointmentTimes
    }))
  }

  deleteAppointmentTimeSlot = (index) => {
    const { eventAppointmentTimes } = this.state;
    const { id } = eventAppointmentTimes[index];

    if (id) {
      this.context.setState(s => { return { ...s, loading: true }});
      axiosInstance.post(`registration/deleteEventBlockTime/${id}`)
        .then(res => {
          eventAppointmentTimes.splice(index, 1);

          this.setState(s => ({
            ...s,
            eventAppointmentTimes,
          }))
        })
        .catch(err => {
          if (err.response) {
            this.props.onError(err.response.data)
          } else {
            this.props.onError("Something went wrong trying to insert data")
          }
        })
        .finally(() => {
          this.context.setState(s => { return { ...s, loading: false }});
        });
    } else {
      eventAppointmentTimes.splice(index, 1);

      this.setState(s => ({
        ...s,
        eventAppointmentTimes,
      }))
    }
  }

  readFile = (file, name) => {
    var reader = new FileReader();
    var fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        var arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i]);
        }
      }
    }

    this.setState({[name]: fileByteArray});
  }

  render() {
    const events = this.state.appointmentBlocks ? this.state.appointmentBlocks.map((item, index) => ({
      id: index,
      title: item.blockName,
      start: new Date(item.blockStartDateTime),
      end: new Date(item.blockEndDateTime)
    })) : []

    return (
      <>
        <style>
          {
            `.carousel {
                max-width: 650px;
             }
             .carousel-item {
                max-width: 650px;
                max-height: 300px;
                height: 500px;
                background: grey;
              }
             .empty-item {
                width: calc(100vw - 90px);
                max-width: 650px;
                max-height: 300px;
                height: 500px;
                background: grey;
             }
              .delete-button {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 500000;
             }`
          }
        </style>
        <Form className="form" onSubmit={(e) => this.submitForm(e)}>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="name"
                  >
                    Event Name *
                  </label>
                  <Input
                    id="name"
                    name="name"
                    value={this.state.name || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Event Name"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input 
                      id="ymca"
                      name="ymca"
                      checked={this.state["ymca"] || false}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    Is this a YMCA Event?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input 
                      id="practiceEvent"
                      name="practiceEvent"
                      checked={this.state["practiceEvent"] || false}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    Is this a practice event?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input 
                      id="registrationsAvailable"
                      name="registrationsAvailable"
                      checked={this.state["registrationsAvailable"] || false}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    Show this event on the registrations page?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input 
                      id="nextEvent"
                      name="nextEvent"
                      checked={this.state["nextEvent"] || false}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    Show this event first on the registrations page?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="startDate"
                  >
                    Start Date *
                  </label>
                  <br />
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.handleDateChange("startDate", date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<Input disabled={this.props.readonly} />}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="endDate"
                  >
                    End Date *
                  </label>
                  <br />
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.handleDateChange("endDate", date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<Input disabled={this.props.readonly} />}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="venue"
                  >
                    Venue Name
                  </label>
                  <Input
                    id="venue"
                    name="venue"
                    value={this.state.venue || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Venue"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="timeZone"
                  >
                    Time Zone *
                  </label>
                  <Input 
                    id="timeZone"
                    name="timeZone"
                    value={this.state.timeZone || ""}
                    onChange={ (e) => this.handleChange(e) }
                    type="select" 
                    required
                    disabled={this.props.readonly}
                  >
                    {this.state.timeZones.map((item, index) => {
                      return <option key={index}>{item}</option>
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="registrationBanner1"
                  >
                    Registration Banner 1
                  </label>
                  <Input
                    id="registrationBanner1"
                    name="registrationBanner1"
                    value={this.state.registrationBanner1 || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Registration Banner 1"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="registrationBanner2"
                  >
                    Registration Banner 2
                  </label>
                  <Input
                    id="registrationBanner2"
                    name="registrationBanner2"
                    value={this.state.registrationBanner2 || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Registration Banner 2"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="registrationBanner3"
                  >
                    Registration Banner 3
                  </label>
                  <Input
                    id="registrationBanner3"
                    name="registrationBanner3"
                    value={this.state.registrationBanner3 || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Registration Banner 3"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">
                    Max Supplier Appointments *
                  </label>
                  <Input
                    id="maxSupplierAppointments"
                    name="maxSupplierAppointments"
                    value={this.state.maxSupplierAppointments || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Max Supplier Appointments"
                    type="number"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-5 justify-content-center align-items-center">
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                className="carousel"
              >
                <CarouselIndicators items={Object.keys(this.state.attachments)} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                {Object.keys(this.state.attachments).map((key, index) => {
                  if (this.state.attachments[key]) {
                    return (
                      <CarouselItem
                        className="carousel-item"
                        onExiting={() => this.setState({animating: true})}
                        onExited={() => this.setState({animating: false})}
                        key={key}
                      >
                        <img src={this.state.attachments[key]} alt="Event attachment" style={{maxHeight: 300, width: "100%"}} />
                        <Button
                          color="danger"
                          className="delete-button"
                          onClick={() => this.deleteEventImage(index + 1)}
                        >Delete</Button>
                      </CarouselItem>  
                    )
                  } else {
                    return (
                      <CarouselItem
                        className="empty-item"
                        onExiting={() => this.setState({animating: true})}
                        onExited={() => this.setState({animating: false})}
                        key={key}
                      >
                        {this.state.id && <ImageUploader
                          style={{height: "100%"}}
                          withIcon={false}
                          buttonText='Choose image'
                          imgExtension={['.jpg', '.png', '.jpeg']}
                          accept=".jpg, .png, .jpeg"
                          maxFileSize={5242880}
                          withPreview={false}
                          onChange={e => this.saveEventImage(e, (index + 1))}
                          label={`Event Image ${index + 1}. Max file size: 5mb, accepted: jpg|png`}
                          labelStyles={{color: "white"}}
                          fileContainerStyle={{height: "100%", background: "#464646", margin: 0}}
                        />}
                      </CarouselItem>  
                    )
                  }
                })}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
              </Carousel>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">
                    Buyer acknowledge registration email template
                  </label>
                  {this.state.buyerRegistrationEmailTemplate && <i className="fas fa-check" style={{ marginLeft: 10, color: "green"}} />}
                  <Input
                    id="buyerRegistrationEmailTemplate"
                    name="buyerRegistrationEmailTemplate"
                    onChange={ (e) => this.readFile(e.target.files[0], "buyerRegistrationEmailTemplate") }
                    type="file"
                    accept=".docx"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">
                    Buyer travel & hotel final confirmation email template
                  </label>
                  {this.state.buyerFinalConfirmationEmailTemplate && <i className="fas fa-check" style={{ marginLeft: 10, color: "green"}} />}
                  <Input
                    id="buyerFinalConfirmationEmailTemplate"
                    name="buyerFinalConfirmationEmailTemplate"
                    onChange={ (e) => this.readFile(e.target.files[0], "buyerFinalConfirmationEmailTemplate") }
                    type="file"
                    accept=".docx"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">
                    Supplier acknowledge registration email template
                  </label>
                  {this.state.supplierRegistrationEmailTemplate && <i className="fas fa-check" style={{ marginLeft: 10, color: "green"}} />}
                  <Input
                    id="supplierRegistrationEmailTemplate"
                    name="supplierRegistrationEmailTemplate"
                    onChange={ (e) => this.readFile(e.target.files[0], "supplierRegistrationEmailTemplate") }
                    type="file"
                    accept=".docx"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">
                    Supplier travel & hotel final confirmation email template
                  </label>
                  {this.state.supplierConfirmationEmailTemplate && <i className="fas fa-check" style={{ marginLeft: 10, color: "green"}} />}
                  <Input
                    id="supplierConfirmationEmailTemplate"
                    name="supplierConfirmationEmailTemplate"
                    onChange={ (e) => this.readFile(e.target.files[0], "supplierConfirmationEmailTemplate") }
                    type="file"
                    accept=".docx"
                  />
                </FormGroup>
              </Col>
            </Row>
            {this.state.startDate && <Calendar
              selectable
              localizer={momentLocalizer(moment)}
              events={events}
              defaultView={Views.WEEK}
              scrollToTime={this.state.startDate ? new Date(moment(this.state.startDate).startOf("week")) : new Date()}
              defaultDate={this.state.startDate ? new Date(moment(this.state.startDate).startOf("week")) : new Date()}
              onSelectEvent={this.handleSelectEvent}
              onSelectSlot={this.handleSelectSlot}
              onSelecting={slot => false}
            />}
          </CardBody>
          <CardFooter>
            <Row>
              <Col />
              <Col lg="auto">
                <Button
                  color={this.state.appointmentsSchedulingStopped != null ? "success" : "danger"}
                  disabled={this.context.loading}
                  onClick={() => this.setState({stopAppointmentsOpen: true})}
                >
                  {this.state.appointmentsSchedulingStopped != null ? "Start Appointment Scheduling" : "Stop Appointment Scheduling"}
                </Button>
                <Button type="submit" color="dark" disabled={this.context.loading}>Submit</Button>
              </Col>
            </Row>
          </CardFooter>
        </Form>
        <Modal size="lg" isOpen={this.state.stopAppointmentsOpen}>
          <ModalHeader toggle={() => this.setState({ stopAppointmentsOpen: false })}>
            {this.state.appointmentsSchedulingStopped == null
              ? "Stop Scheduling Appointments?"
              : "Start Scheduling Appointments?"}
          </ModalHeader>
          <ModalBody>
            {this.state.appointmentsSchedulingStopped == null
              ? "Are you sure that you want to stop scheduling appointments for this event? Suppliers will not be able to log in."
              : "Are you sure that you want to start scheduling appointments for this event?"}
          </ModalBody>
          <CardFooter>
            <Row>
              <Col />
              <Button
                color="dark"
                disabled={this.context.loading}
                onClick={this.toggleSchedulingStopped}
              >
                {this.state.appointmentsSchedulingStopped == null
                  ? "Stop Scheduling Appointments"
                  : "Start Scheduling Appointments"}
              </Button>
            </Row>
          </CardFooter>
        </Modal>
        <Modal isOpen={!!this.state.openBlockModal} size="lg">
          <Form className="form" onSubmit={(e) => this.submitBlockForm(e)}>
            <ModalHeader toggle={() => { this.setState({openBlockModal: false}) }}>{new Date(this.state.blockStartDateTime).toDateString()} - Appointment Block</ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">
                      Appointment Block Name *
                    </label>
                    <Input
                      id="blockName"
                      name="blockName"
                      value={this.state.blockName || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Appointment Block Name"
                      type="text"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Appointment Block Start Date *
                    </label>
                    <ReactDatetime
                      id="blockStartDateTime"
                      value={new Date(this.state.blockStartDateTime) || ""}
                      onChange={ (e) => this.handleTimeChange("blockStartDateTime", e) }
                      inputProps={{
                        placeholder: "Start Date", 
                        required: true,
                      }}
                      dateFormat={false}
                      timeFormat={true}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                    >
                      Appointment Block End Date *
                    </label>
                    <ReactDatetime
                      id="blockEndDateTime"
                      value={new Date(this.state.blockEndDateTime) || ""}
                      onChange={ (e) => this.handleTimeChange("blockEndDateTime", e) }
                      inputProps={{
                        placeholder: "End Date", 
                        required: true,
                      }}
                      dateFormat={false}
                      timeFormat={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="pb-3"> 
                <Col>
                  <label>Appointment Time Slots</label>
                </Col>
                <Button size="sm" className="mr-3" color="dark" disabled={this.context.loading} onClick={this.addAppointmentTimeSlot}>Add Time Slot</Button>
              </Row>
              <ListGroup>
                {this.state.eventAppointmentTimes.map((item, index) => (
                <ListGroupItem className="justify-content-between bg-secondary" key={index}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                        >
                          Time Slot - Start
                        </label>
                        <ReactDatetime
                          id="startTime"
                          value={new Date(item.startTime) || ""}
                          onChange={ (e) => this.handleTimeSlotDateChange("startTime", index, e) }
                          inputProps={{
                            placeholder: "Start Time", 
                            required: true,
                          }}
                          dateFormat={false}
                          timeFormat={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                      <label
                          className="form-control-label"
                        >
                          Time Slot - End
                        </label>
                        <ReactDatetime
                          id="endTime"
                          value={new Date(item.endTime) || ""}
                          onChange={ (e) => this.handleTimeSlotDateChange("endTime", index, e) }
                          inputProps={{
                            placeholder: "End Time", 
                            required: true,
                          }}
                          dateFormat={false}
                          timeFormat={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="auto">
                      <Button size="sm" className="mt-5" color="danger" disabled={this.context.loading} onClick={() => {this.deleteAppointmentTimeSlot(index)}}>Delete</Button>
                    </Col>
                  </Row>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        name="active"
                        checked={item.notAvailable || false}
                        onChange={ (e) => this.handleTimeSlotNotAvailableChange(index, e.target.checked) }
                        type="checkbox"
                      />
                        <label className="form-control-label" style={{color: "#32325d"}}>Not Available</label>
                    </Label>
                  </FormGroup>
                </ListGroupItem>
                ))}
              </ListGroup>
            </ModalBody>
            <CardFooter>
              <Row>
                {this.state.blockId != null && <Button onClick={this.handleDeleteAppointment} color="danger" disabled={this.context.loading}>Delete</Button>}
                <Col />
                <Button type="submit" color="dark" disabled={this.context.loading}>Done</Button>
              </Row>
            </CardFooter>
          </Form>
        </Modal>
      </>
    );
  }
}
export default Elements;
