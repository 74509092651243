/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import SupplierForm from "components/Forms/SupplierForm.js"
import { UserContext } from "../../lib/UserContext";
import axiosInstance from "api";

class Supplier extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      event: null,
      events: []
    };

    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    const eventId = new URLSearchParams(this.props.location.search).get("event");

    this.context.setState(s => { return {...s, loading: true}})
    const url = eventId ? `registration/allEvents/${eventId}` : "registration/allEvents";
    axiosInstance.get(url)
      .then(resp => {
        this.setState({event: resp.data[0] ? resp.data[0] : null, events: resp.data});
      })
      .finally(() => {
        this.context.setState(s => { return {...s, loading: false}});
      });
  }

  onSuccess = () => {
    this.setState({ error: null, success: true })
  }

  onError = (err) => {
    this.setState({ error: err, success: false })
  }

  selectEvent = (e) => {
    e.preventDefault();
    const { target } = e;
    const { value } = target;

    const foundEvent = this.state.events.find(event => event.id.toString() === value);
    this.setState({event: foundEvent});
  }

  render() {
    return (
      <>
        <AuthHeader title="Host Supplier Registration Form" />
        <Container className="mt--9" fluid>
          {!this.state.success &&   
          <Card className="col-md-8 offset-md-2 mb-4">
            {this.state.event && <>
              <CardHeader className="text-center">
                <span>Please choose an event</span>
                <br/>
                <div style={{height: 10}}></div>
                <FormGroup style={{marginBottom: 10}}>
                  <Input
                    value={this.state.event.id || ""}
                    onChange={ (e) => this.selectEvent(e) }
                    type="select" 
                    disabled={this.props.readonly}
                    style={{textAlign: "center", fontWeight: 900, color: "#32325d", marginLeft: 8}}
                  >
                    {this.state.events.map((item, index) => {
                      return <option key={item.id} value={item.id}>{item.registrationBanner1}</option>
                    })}
                  </Input>
                </FormGroup>
                {(this.state.event.registrationBanner1 ||
                  this.state.event.registrationBanner2 ||
                  this.state.event.registrationBanner3) && (
                  <>
                    <span>{this.state.event.registrationBanner2}</span>
                    <br/>
                    <div style={{height: 10}}></div>
                    <span>{this.state.event.registrationBanner3}</span>
                  </>
                )}
              </CardHeader>
              <SupplierForm
                verifyEmail
                eventId={this.state.event.id}
                onError={this.onError}
                onSuccess={this.onSuccess}
                deletePhoto={() => {}}
                data={{
                  blocks: [...this.state.event.eventAppointmentBlocks],
                  startDate: this.state.event.startDate,
                  attachments: this.state.event.attachments,
                  timeZone: this.state.event.timeZone
                }}
              />
            </>}
            {!this.state.event && <>
              <CardHeader className="text-center">No Events Found</CardHeader>
              <CardBody>
                <p className="text-center">
                  There are no upcoming events in our schedule.
                </p>
              </CardBody>
            </>}
          </Card>}
          {this.state.success && 
          <Card className="col-md-8 offset-md-2 mb-4">
            <CardHeader className="text-center">Registration Complete</CardHeader>
            <CardBody>
              <div className="text-center">
                <div className="icon icon-xl icon-shape bg-success shadow rounded-circle text-white">
                  <i className="fas fa-check" />
                </div>
              </div>
              <br />
              <p>
                Form submitted successfully. You will receive a confirmation email. Please contact FISA if you did not receive your confirmation email.
              </p>
            </CardBody>
          </Card>}
          <Modal isOpen={!!this.state.error}>
            <ModalHeader toggle={() => { this.setState({error: null}) }}>Error</ModalHeader>
            <ModalBody>
              {this.state.error}
            </ModalBody>
          </Modal>
        </Container>
      </>
    );
  }
}
export default Supplier;
