/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Events from "views/dashboards/Events.js";
import Buyers from "views/dashboards/Buyers.js";
import Suppliers from "views/dashboards/Suppliers.js";
import Settings from "views/dashboards/Settings.js";
import Appointments from "views/events/Appointments.js";
import Buyer from "views/events/Buyer.js";
import Supplier from "views/events/Supplier.js";
import Login from "views/pages/examples/Login.js";
import Profile from "views/pages/examples/Profile.js";
import Register from "views/pages/examples/Register.js";
import ForgotPassword from "views/pages/examples/ForgotPassword.js";

const routes = [
  {
    collapse: true,
    name: "Admin",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Events",
        miniName: "E",
        component: Events,
        layout: "/admin"
      },
      {
        path: "/buyers",
        name: "Buyers",
        miniName: "B",
        component: Buyers,
        layout: "/admin"
      },
      {
        path: "/suppliers",
        name: "Suppliers",
        miniName: "S",
        component: Suppliers,
        layout: "/admin"
      },
      {
        path: "/settings",
        name: "Settings",
        miniName: "S",
        component: Settings,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    hidden: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/forgot",
        name: "Forgot Password",
        miniName: "F",
        component: ForgotPassword,
        layout: "/auth"
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin"
      },
      {
        path: "/appointments",
        name: "Appointments",
        component: Appointments,
        layout: "/auth"
      },
      {
        path: "/buyerform",
        name: "VIP Guest Registration",
        component: Buyer,
        layout: "/auth"
      },
      {
        path: "/supplierform",
        name: "Host Supplier Registration",
        component: Supplier,
        layout: "/auth"
      },
    ]
  },
];

export default routes;
