/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// react library for routing
import { Link } from "react-router-dom";

// reactstrap components
import {
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import LogoImage from "assets/img/brand/fisa-logo.png";
import LogoImageWhite from "assets/img/brand/fisa-logo-white.png";

class AdminNavbar extends React.Component {
  state = {
    isOpen: false 
  }

  render() {
    return (
      <>
        <div className="w-100 flex bg-darker text-center py-1">
          <span className="text-white text-center" style={{fontSize: 12}}>
            <i className="far fa-question-circle fa-lg" /> Need Help? Call Christine Norell at <a className="text-reset text-light" href="tel:+18587359238">(858) 735-9238</a> or Sal Pellegrino at <a className="text-reset text-light" href="tel:+19142132112">(914) 213-2112</a>.  We're happy to Assist.
          </span>
        </div>
        <Navbar
          className="navbar-horizontal navbar-main navbar-light navbar-transparent mt-5 mt-sm-4"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <img src={LogoImageWhite} height="50px" alt="logo" className="d-none d-md-block"/>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              id="navbar-collapse"
              type="button"
              onClick={() => this.setState({isOpen: true})}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Collapse
              className="navbar-custom-collapse"
              navbar
              isOpen={this.state.isOpen}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/admin/dashboard">
                      <img
                        alt="..."
                        src={LogoImage}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      id="navbar-collapse"
                      type="button"
                      onClick={() => this.setState({isOpen: false})}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink to="/" tag={Link} className="text-white" onClick={() => this.setState({isOpen: false})}>
                    <span className="nav-link-inner--text">Home</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/auth/supplierform" tag={Link} className="text-white" onClick={() => this.setState({isOpen: false})}>
                    <span className="nav-link-inner--text">Host Supplier Registration</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/auth/buyerform" tag={Link} className="text-white" onClick={() => this.setState({isOpen: false})}>
                    <span className="nav-link-inner--text">VIP Guest Registration</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
