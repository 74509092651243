/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Get our configs.
import * as config from "./config";

import React from "react";
import ReactDOM from "react-dom";

// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Auth imports.
import Amplify from "aws-amplify";
import { UserContextProvider } from "./lib/UserContext";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import Logo from "./assets/img/brand/fisa-logo.png";

import { Row } from "reactstrap";
import { browserName } from 'react-device-detect';

// Setup AWS client so we can communicate
// with their Cognito service.
Amplify.configure(config.amplify);

const validBrowser = (
  <UserContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/" render={(props) => <IndexView {...props} />} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  </UserContextProvider>
)

const invalidBrowser = (
  <div className="grid-tools FullSite" style={{ marginTop: 40 }}>
    <div className="container">
      <div className="container-wrapper">
        <div className="container-full-column">
          <Row className="justify-content-center mb-3">
            <img
              src={Logo}
              className="img-fluid"
              alt="logo"
              />
          </Row>

          <Row className="justify-content-center mb-3">
            <div className="unsupported-browser">
              <h1>Sorry, We do not support the Safari Browser</h1>
              <h2>Please download one of these free and up-to-date browsers:</h2>
              <ul>
                <li><a href="https://www.google.com/chrome/browser/desktop/" target="_blank">Chrome</a></li>
                <li><a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Microsoft Edge</a></li>
              </ul>
            </div>
          </Row>
        </div>
      </div>
    </div>
  </div>
)

ReactDOM.render(
  browserName.includes('Safari')
    ? invalidBrowser
    : validBrowser,
  document.getElementById("root"),
);
