/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import DatePicker from "react-datepicker";
import "../../assets/css/datepicker.css";
// reactstrap components
import {
  CardHeader,
  CardBody,
  Label,
  Form,
  FormGroup,
  Button,
  Input,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import moment from "moment-timezone";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import CardFooter from "reactstrap/lib/CardFooter";
import axiosInstance from "api";
import { UserContext } from "lib/UserContext";
// import Avatar from "react-avatar-edit";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import NumberFormat from 'react-number-format';

class Elements extends React.Component {
  static contextType = UserContext;
  
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.data,
      hotelArrivalDate: this.props.data.hotelArrivalDate ? new Date(this.props.data.hotelArrivalDate) : null,
      hotelDepartureDate: this.props.data.hotelDepartureDate ? new Date(this.props.data.hotelDepartureDate) : null,
      organizationCountry: this.props.data.organizationCountry ? this.props.data.organizationCountry : "United States",
      organizationState: this.props.data.organizationState ? this.props.data.organizationState : "",
      attachments: this.props.data.attachments ?? {},
      genderOptions: [
        "Male",
        "Female"
      ],
      preview: null,
      src: "",
      activeIndex: 0,
      animating: false,
      showSchedule: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this)
    this.validateForm = this.validateForm.bind(this);
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    this.canUploadImage = this.canUploadImage.bind(this)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.allAttachmentsPresent = this.allAttachmentsPresent.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCellPhoneChange = this.handleCellPhoneChange.bind(this);

    moment.tz.setDefault(this.props.data.timeZone);
  }

  componentDidMount() {
    if (this.props.data) {
      if (this.props.data.imageString) {
        this.setState({editProfilePic: false, src: this.props.data.imageString});
      } else {
        this.setState({editProfilePic: true, src: ""});
      }
    }
  }

  componentDidUpdate() {
    moment.tz.setDefault(this.props.data.timeZone);
  }

  allAttachmentsPresent = () => {
    let allPresent = true;
    for (const key of Object.keys(this.props.data.attachments)) {
      if (!this.props.data.attachments[key]) {
        allPresent = false;
      }
    }
    return allPresent;
  }

  canUploadImage = () => {
    if (this.props.data) {
      if (this.props.data.imageString) {
        return true;
      }
    }

    return false;
  }

  next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === Object.keys(this.props.data.attachments).length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? Object.keys(this.props.data.attachments).length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({activeIndex: newIndex});
  }


  onClose() {
    this.setState({preview: null})
  }
  
  onCrop(preview) {
    this.setState({preview})
  }

  onBeforeFileLoad(elem) {
    if(elem.target.files[0].size > 71680){
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  handleCountryChange = (value) => {
    this.setState({organizationCountry: value});
  }

  handleRegionChange = (value) => {
    this.setState({organizationState: value});
  }

  handlePrimaryTransportCellphoneChange = (e) => {
    this.setState({productTransporterCellphone: e.value});
  }

  handlePhoneChange = (e) => {
    this.setState({phone: e.value});
  }

  handleCellPhoneChange = (e) => {
    this.setState({cellphone: e.value});
  }

  handleChange = async (event) => {
    const uppercaseFirstLetter = (v) => v.charAt(0).toUpperCase() + v.slice(1);
    const uppercaseFirstLetterLowercaseRest = (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
    const { target } = event;
    const { name } = target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (name === "firstName" || name === "lastName") {
      const regex = /[A-Z]{3,100}/;
      value = uppercaseFirstLetter(value);
      if (regex.test(value)) {
        value = uppercaseFirstLetterLowercaseRest(value);
      }
    }
    await this.setState({
      [ name ]: value,
    });
  }

  handleDateChange = async (name, value) => {
    await this.setState({
      [ name ]: value,
    });
  }

  validateForm() {
    if (this.props.verifyEmail && this.state.email !== this.state.verifyEmail) {
      this.props.onError("Email and Verify Email fields do not match.");
      return false;
    }

    return true;
  }

  submitForm(e) {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const payload = {
      eventId: this.props.eventId,
      registration: {
        id: this.state.registrationId,
        personId: this.state.personId,
        buyer: false,
        primaryRepresentative: this.state.primaryRepresentative,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        suffix: this.state.suffix || "",
        title: this.state.title,
        badgeName: this.state.badgeName,
        organizationName: this.state.organizationName,
        gender: this.state.gender || this.state.genderOptions[0],
        email: this.state.email,
        webUrl: this.state.webUrl,
        phone: this.state.phone,
        cellphone: this.state.cellphone,
        preferCellphone: this.state.preferCellphone,
        dietaryRestrictions: this.state.dietaryRestrictions,
        hotelArrivalDate: this.state.hotelArrivalDate.toDateString(),
        hotelDepartureDate: this.state.hotelDepartureDate.toDateString(),
        hotelConfirmationNumber: this.state.hotelConfirmationNumber,
        hotelShareRoom: this.state.hotelShareRoom,
        singleRoom: this.state.singleRoom,
        hotelShareRoomPartner: this.state.hotelShareRoomPartner,
        guestOfGuestTotalDue: this.state.guestOfGuestTotalDue,
        guestOfGuestFeesDetails: this.state.guestOfGuestFeesDetails,
        specialArrangementsFees: this.state.specialArrangementsFees,
        nightsPaidByFisa: this.state.nightsPaidByFisa,
        nightsPaidByGuest: this.state.nightsPaidByGuest,
        bedsNeeded: this.state.bedsNeeded,
        singleOccupancy: this.state.singleOccupancy,
        submittedDateTime: new Date(),
        imageString: this.state.preview
      },
      supplierRegistration: {
        id: this.state.id,
        registrationId: this.state.registrationId,
        shippingDemonstrationProduct: this.state.shippingDemonstrationProduct,
        productTransporterName: this.state.productTransporterName,
        productTransporterCompany: this.state.productTransporterCompany,
        productTransporterEmail: this.state.productTransporterEmail,
        productTransporterCellphone: this.state.productTransporterCellphone,
        organizationAddressLine1: this.state.organizationAddressLine1,
        organizationAddressLine2: this.state.organizationAddressLine2,
        organizationCity: this.state.organizationCity,
        organizationCountry: this.state.organizationCountry,
        organizationState: this.state.organizationState,
        organizationZip: this.state.organizationZip,
        assistantName: this.state.assistantName,
        assistantEmail: this.state.assistantEmail,
        archived: this.state.archived ?? false
      },
    }

    this.context.setState(s => { return { ...s, loading: true }});
    axiosInstance.post("/registration/insertSupplier", payload)
      .then(resp => {
        this.props.onSuccess();
      })
      .catch(err => {
        this.props.onError("There was an error with the request. Please check the fields and try again.");
      })
      .finally(() => {
        this.context.setState(s => { return { ...s, loading: false }});
      })
  }

  Event = ({ event }) => {
    return (
      <span>
        <strong>{event.title}</strong>
        {event.desc && ":  " + event.desc}
      </span>
    );
  };

  EventAgenda = ({ event }) => {
    return (
      <span className="w-100">
        <span style={{ color: "default" }}>
          <span className="text-muted">
            <i className="fas fa-calendar mr-2" /> {event.title}
          </span>
        </span>
      </span>
    );
  };


  render() {
    const eventAppointmentBlocks = (this.state.blocks
      ? this.state.blocks
      : []
    ).map((item, index) => ({
      id: index,
      title: item.blockName,
      start: new Date(item.blockStartDateTime),
      end: new Date(item.blockEndDateTime),
    }));

    const events = [...eventAppointmentBlocks];

    return (
      <>
        <style>
          {
            `.carousel {
              max-width: 650px;
            }
            .carousel-item {
              max-width: 650px;
              max-height: 300px;
              height: 100%;
              background: grey;
            }`
          }
        </style>
        <Form className="form" onSubmit={(e) => this.submitForm(e)}>
          <CardBody>
          {this.props.data.attachments && this.allAttachmentsPresent() && (
            <Row className="mb-5 justify-content-center align-items-center">
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                className="carousel"
              >
                <CarouselIndicators items={Object.keys(this.props.data.attachments)} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                {Object.keys(this.props.data.attachments).map((key) => {
                    if (this.props.data.attachments[key]) {
                      return (
                        <CarouselItem
                          className="carousel-item"
                          onExiting={() => this.setState({animating: true})}
                          onExited={() => this.setState({animating: false})}
                          key={key}
                        >
                          <img src={this.props.data.attachments[key]} alt="Event attachment" style={{maxHeight: 300, width: "100%"}} />
                        </CarouselItem>  
                      )
                    } else {
                      return (
                        <CarouselItem
                          className="carousel-item"
                          onExiting={() => this.setState({animating: true})}
                          onExited={() => this.setState({animating: false})}
                          key={key}
                        >
                          <></>
                        </CarouselItem>  
                      )
                    }
                  })}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
              </Row>
            )}
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="firstName"
                  >
                    First Name *
                  </label>
                  <Input
                    id="firstName"
                    name="firstName"
                    value={this.state.firstName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="First Name"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="lastName"
                  >
                    Last Name *
                  </label>
                  <Input
                    id="lastName"
                    name="lastName"
                    value={this.state.lastName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Last Name"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="title"
                  >
                    Job Title *
                  </label>
                  <Input
                    id="title"
                    name="title"
                    value={this.state.title || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Job Title"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="badgeName"
                  >
                    Badge Name *
                  </label>
                  <Input
                    id="badgeName"
                    name="badgeName"
                    value={this.state.badgeName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="First Name on Badge"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="gender"
                  >
                    Gender *
                  </label>
                  <Input 
                    id="gender"
                    name="gender"
                    value={this.state.gender || ""}
                    onChange={ (e) => this.handleChange(e) }
                    type="select" 
                  >
                    {this.state.genderOptions.map((item, index) => {
                      return <option key={index}>{item}</option>
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{fontWeight: "bold", color: "#8c95aa"}}><strong>Only one representative may make appointments for your company</strong></p>
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  className="form-control-label"
                >
                  Are you the representative responsible for  making appointments on the appointment-matching day?
                </label>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="primaryRepresentative"
                      checked={this.state.primaryRepresentative || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    Yes
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="shippingDemonstrationProduct"
                      checked={this.state.shippingDemonstrationProduct || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    I am shipping/bringing a product to demonstrate at this event
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          {this.state.shippingDemonstrationProduct && (
            <>
              <CardHeader>
                <h3 className="mb-0">Transportation Contact Info</h3>
                <p className="mb-0" style={{fontWeight: "bold", color: "#8c95aa"}}><strong>Please provide a contact person responsible for shipping/bringing product</strong></p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="productTransporterName"
                      >
                        Name *
                      </label>
                      <Input
                        id="productTransporterName"
                        name="productTransporterName"
                        value={this.state.productTransporterName || ""}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Name"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="productTransporterCompany"
                      >
                        Company *
                      </label>
                      <Input
                        id="productTransporterCompany"
                        name="productTransporterCompany"
                        value={this.state.productTransporterCompany || ""}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Company"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="productTransporterEmail"
                      >
                        Email *
                      </label>
                      <Input
                        id="productTransporterEmail"
                        name="productTransporterEmail"
                        value={this.state.productTransporterEmail || ""}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Email"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="productTransporterCellphone"
                      >
                        Phone Number *
                      </label>
                      <NumberFormat
                        id="productTransporterCellphone"
                        name="productTransporterCellphone"
                        value={this.state.productTransporterCellphone || ""}
                        onValueChange={ (e) => this.handlePrimaryTransportCellphoneChange(e) }
                        format="+1 (###) ###-####"
                        placeholder="+1 (___) ___ - ____"
                        mask="_"
                        required
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </>
          )}
          <CardHeader>
            <h3 className="mb-0">Communication Profile</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="phone"
                  >
                    Phone *
                  </label>
                  <NumberFormat
                    id="phone"
                    name="phone"
                    value={this.state.phone || ""}
                    onValueChange={ (e) => this.handlePhoneChange(e) }
                    format="+1 (###) ###-####"
                    placeholder="+1 (___) ___ - ____"
                    mask="_"
                    required
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="cellphone"
                  >
                    Cellphone *
                  </label>
                  <NumberFormat
                    id="cellphone"
                    name="cellphone"
                    value={this.state.cellphone || ""}
                    onValueChange={ (e) => this.handleCellPhoneChange(e) }
                    placeholder="+1 (___) ___ - ____"
                    format="+1 (###) ###-####"
                    mask="_"
                    required
                    className="form-control"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  className="form-control-label"
                >
                  What's the best way to reach you?
                </label>
                <FormGroup check>
                  <Label check>
                    <Input 
                      name="preferCellphone"
                      checked={this.state.preferCellphone || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    I prefer cellphone
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="email"
                  >
                    Email *
                  </label>
                  <Input
                    id="email"
                    name="email"
                    value={this.state.email || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Email"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {this.props.verifyEmail && <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="verifyEmail"
                  >
                    Verify Email *
                  </label>
                  <Input
                    id="verifyEmail"
                    name="verifyEmail"
                    value={this.state.verifyEmail || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Verify Email"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>}
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="webUrl"
                  >
                    Website *
                  </label>
                  <Input
                    id="webUrl"
                    name="webUrl"
                    value={this.state.webUrl || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Website"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardHeader>
            <h3 className="mb-0">Organization Information</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationName"
                  >
                    Organization/Information Name *
                  </label>
                  <Input
                    id="organizationName"
                    name="organizationName"
                    value={this.state.organizationName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Company/Information Name"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationAddressLine1"
                  >
                    Organization Address 1 *
                  </label>
                  <Input
                    id="organizationAddressLine1"
                    name="organizationAddressLine1"
                    value={this.state.organizationAddressLine1 || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Organization Address 1"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationAddressLine2"
                  >
                    Organization Address 2
                  </label>
                  <Input
                    id="organizationAddressLine2"
                    name="organizationAddressLine2"
                    value={this.state.organizationAddressLine2 || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Organization Address 2"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationCity"
                  >
                    Organization City *
                  </label>
                  <Input
                    id="organizationCity"
                    name="organizationCity"
                    value={this.state.organizationCity || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Organization City"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationCountry"
                  >
                    Organization Country *
                  </label>
                  <CountryDropdown
                    id="organizationCountry"
                    name="organizationCountry"
                    value={this.state.organizationCountry || ""}
                    onChange={ (e) => this.handleCountryChange(e) }
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationState"
                  >
                    Organization State/Province *
                  </label>
                  <RegionDropdown
                    id="organizationState"
                    name="organizationState"
                    country={this.state.organizationCountry || ""}
                    value={this.state.organizationState || ""}
                    onChange={ (e) => this.handleRegionChange(e) }
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="organizationZip"
                  >
                    Organization Zip/Postal Code *
                  </label>
                  <Input
                    id="organizationZip"
                    name="organizationZip"
                    value={this.state.organizationZip || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Zip/Postal Code"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardHeader>
            <h3 className="mb-1">Assistant Information</h3>
            <p className="mb-0">If you would like a copy of your appointment schedule and final hotel and travel confirmation
            sent to an administrative assistant or business partner, please give us their contact information.</p>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="assistantName"
                  >
                    Assistant Name
                  </label>
                  <Input
                    id="assistantName"
                    name="assistantName"
                    value={this.state.assistantName || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Assistant Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="assistantEmail"
                  >
                    Assistant Email
                  </label>
                  <Input
                    id="assistantEmail"
                    name="assistantEmail"
                    value={this.state.assistantEmail || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Assistant Email"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardHeader>
            <h3 className="mb-0">Hotel & Transportation Information</h3>
            <p className="mb-0" style={{fontWeight: 500}}>FISA will cover up to 3 room nights</p>
          </CardHeader>
          <CardBody>
            {this.state.blocks && this.state.blocks.length > 0 && (
              <>
                <Row className="justify-content-end">
                  <Button
                    color="danger"
                    disabled={this.context.loading}
                    onClick={() => this.setState({showSchedule: !this.state.showSchedule})}
                  >{this.state.showSchedule ? "Hide Event Schedule" : "View Event Schedule"}</Button>
                </Row>
                {this.state.showSchedule && (
                  <>
                    <Row className="pb-3 pt-3 align-items-center">
                      <Col>
                        <Calendar
                          toolbar={false}
                          selectable
                          localizer={momentLocalizer(moment)}
                          events={events}
                          views={[Views.AGENDA]}
                          defaultView={Views.AGENDA}
                          scrollToTime={
                            this.state.startDate
                              ? new Date(moment(this.state.startDate).startOf("week"))
                              : new Date()
                          }
                          defaultDate={
                            this.state.startDate
                              ? new Date(moment(this.state.startDate).startOf("week"))
                              : new Date()
                          }
                          components={{
                            event: this.Event,
                            agenda: {
                              event: this.EventAgenda,
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="hotelArrivalDate"
                  >
                    Hotel Arrival Date *
                  </label>
                  <br />
                  <DatePicker
                    selected={this.state.hotelArrivalDate}
                    onChange={(date) => this.handleDateChange("hotelArrivalDate", date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                    customInput={<Input disabled={this.props.readonly} />}
                    required
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="hotelDepartureDate"
                  >
                    Hotel Departure Date *
                  </label>
                  <br />
                  <DatePicker
                    selected={this.state.hotelDepartureDate}
                    onChange={(date) => this.handleDateChange("hotelDepartureDate", date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    openToDate={this.props.data.startDate ? new Date(this.props.data.startDate) : new Date()}
                    customInput={<Input disabled={this.props.readonly} />}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input 
                      name="singleOccupancy"
                      checked={this.state.singleOccupancy || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                      disabled={this.props.readonly}
                    />
                    I would like a single hotel room (Single Occupancy)
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            OR
            <br />
            <br />
            <Row>
              <Col>
                <label
                  className="form-control-label"
                >
                  Are you sharing a hotel room with someone?
                </label>
                <FormGroup check>
                  <Label check>
                    <Input 
                      name="hotelShareRoom"
                      checked={this.state.hotelShareRoom || ""}
                      onChange={ (e) => this.handleChange(e) }
                      type="checkbox" 
                    />
                    Yes, I am sharing a hotel room
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            {this.state.hotelShareRoom && <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="hotelShareRoomPartner"
                  >
                    Who are you sharing a room with?
                  </label>
                  <Input
                    id="hotelShareRoomPartner"
                    name="hotelShareRoomPartner"
                    value={this.state.hotelShareRoomPartner || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Full Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="bedsNeeded"
                  >
                    Number of hotel beds needed
                  </label>
                  <Input 
                    id="bedsNeeded"
                    name="bedsNeeded"
                    value={this.state.bedsNeeded || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="1 or 2 Beds"
                    type="number" 
                  />
                </FormGroup>
              </Col>
            </Row>}
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="dietaryRestrictions"
                  >
                    Dietary Restrictions
                    <p>
                    We will do our best to offer healthy food choices in all catered meals. Do you have any special dietary restrictions i.e. do you require gluten-free or vegetarian meals?
                    </p>
                  </label>
                  <Input
                    id="dietaryRestrictions"
                    name="dietaryRestrictions"
                    value={this.state.dietaryRestrictions || ""}
                    onChange={ (e) => this.handleChange(e) }
                    placeholder="Dietary Restrictions"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* <div className="ml-1">
              <label
                className="form-control-label"
                htmlFor="dietaryRestrictions"
              >
                Profile Picture
                <p>
                  Please upload a headshot photo for your Profile. This photo will appear in the Event Booklet  (which is black and white) and distributed to all attendees.
                  Upload a photo in the resolution range of 300 to 600 dpi.
                  Click the profile picture box to upload your image. (jpg or png)
                </p>
              </label>
              <br/>
              {this.canUploadImage()
              ? (
                <>
                  <img src={this.state.imageString} alt="Preview" />
                  <div><Button color="danger" disabled={this.context.loading} onClick={this.props.deletePhoto} className="ml-2">Delete</Button></div>
                </>
                  
                ) : (
                  <Avatar
                    width={280}
                    height={280}
                    onCrop={this.onCrop}
                    onClose={this.onClose}
                    onBeforeFileLoad={this.onBeforeFileLoad}
                    src={this.state.src}
                    exportAsSquare={true}
                    exportSize={280}
                    label="Profile Picture"
                  />
                )}
            </div> */}
          </CardBody>
          {this.context.user && (
            <>
              <CardHeader style={{ borderBottom: "2px solid red", margin: "0 24px" }}>
                <h1 className="mb-0" style={{color: "red"}}><strong>Admin (Internal Use Only)</strong></h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="guestOfGuestTotalDue"
                    >
                      Guest of Guest Fees amount Due
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        id="guestOfGuestTotalDue"
                        name="guestOfGuestTotalDue"
                        value={this.state.guestOfGuestTotalDue}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Total Due"
                        type="number" 
                        disabled={this.props.readonly}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="guestOfGuestFeesDetails"
                    >
                      Guest of Guest Fees Details
                    </label>
                    <Input
                      id="guestOfGuestFeesDetails"
                      name="guestOfGuestFeesDetails"
                      value={this.state.guestOfGuestFeesDetails || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Details"
                      maxLength="100"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="specialArrangementsFees"
                    >
                      Special Arrangements Fees & Travel
                    </label>
                    <Input
                      id="specialArrangementsFees"
                      name="specialArrangementsFees"
                      value={this.state.specialArrangementsFees || ""}
                      onChange={ (e) => this.handleChange(e) }
                      placeholder="Details"
                      maxLength="100"
                      type="textarea"
                      disabled={this.props.readonly}
                    />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="nightsPaidByFisa"
                      >
                        Nights Paid By FISA
                      </label>
                      <Input
                        id="nightsPaidByFisa"
                        name="nightsPaidByFisa"
                        value={this.state.nightsPaidByFisa}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Nights"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="nightsPaidByGuest"
                      >
                        Nights Paid By Guest
                      </label>
                      <Input
                        id="nightsPaidByGuest"
                        name="nightsPaidByGuest"
                        value={this.state.nightsPaidByGuest}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Nights"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                    >
                      Single Room?
                    </label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="singleRoom"
                          checked={this.state.singleRoom || ""}
                          onChange={ (e) => this.handleChange(e) }
                          type="checkbox" 
                          disabled={this.props.readonly}
                        />
                        Yes
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="hotelConfirmationNumber"
                      >
                        Hotel Confirmation Number
                      </label>
                      <Input
                        id="hotelConfirmationNumber"
                        name="hotelConfirmationNumber"
                        value={this.state.hotelConfirmationNumber || ""}
                        onChange={ (e) => this.handleChange(e) }
                        placeholder="Confirmation Number"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col></Col>
                </Row>
              </CardBody>
            </>
          )}
          <CardFooter>
            <Row>
              <Col />
              <Col lg="auto">
                <Button type="submit" color="dark" disabled={this.context.loading}>Submit</Button>
              </Col>
            </Row>
          </CardFooter>
        </Form>
      </>
    );
  }
}
export default Elements;
