/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import axiosInstance from "api";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { UserContext } from "lib/UserContext";

class Buyers extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      registrationEmailNotifications: "",
      adminBCCFinalConfirmation: "",
      emailScheduleTemplate: "",
      template: null,
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onError = this.onError.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  componentDidMount() {
    if (this.context.activeTenant) {
      const emails = this.context.activeTenant.registrationEmailNotifications;
      const adminEmails = this.context.activeTenant.adminBCCFinalConfirmation;
      const emailScheduleTemplate = this.context.activeTenant.emailScheduleTemplate;
      this.setState(s => ({...s, registrationEmailNotifications: emails, adminBCCFinalConfirmation: adminEmails, emailScheduleTemplate: emailScheduleTemplate}));
    }
  }

  handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [ name ]: value,
    });
  }

  onReset = () => {
    this.setState({ error: null, selectedItem: null })
    const activeEvent = this.context.activeEvent;
    this.context.setState(s => ({...s, activeEvent: null}))
    this.context.selectEvent(activeEvent);
  }

  onError = (err) => {
    this.setState({ error: err })
  }

  saveFile = async () => {
    try {
      this.context.setState(s => { return { ...s, loading: true }});
      const formData = new FormData();
      formData.append('file', this.state.template);
      const response = await axiosInstance.post(`/registration/insert-file/${this.context.activeEvent.id}/emailScheduleTemplate`, formData);
      return response.data;
    } catch (error) {
      this.setState({error: "There was an error with the request. Please check the fields and try again"});
    } finally {
      this.context.setState(s => { return { ...s, loading: false }});
    }
  }

  submitForm(e) {
    e.preventDefault();

    const payload = {
      ...this.context.activeTenant,
      registrationEmailNotifications: this.state.registrationEmailNotifications,
      adminBCCFinalConfirmation: this.state.adminBCCFinalConfirmation
    };

    this.context.setState(s => { return { ...s, loading: true }});
    axiosInstance.put("/user/update-tenant", payload)
      .then(async () => {
        this.context.setState(s => ({...s, activeTenant: payload}));

        if (this.state.template) {
          const response = await this.saveFile();
          this.setState(s => ({...s, emailScheduleTemplate: response}))
          this.context.setState(s => ({...s, activeTenant: {...payload, emailScheduleTemplate: response }}));
        }

        this.onReset();
      })
      .catch(err => {
        this.onError("There was an error with the request. Please check the fields and try again.");
      })
      .finally(() => {
        this.context.setState(s => { return { ...s, loading: false }});
      })
  }

  render() {
    return (
      <>
        <SimpleHeader name="Settings" parentName="Home" />
        {this.context.activeTenant && <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                  <CardHeader>
                    <h3 className="mb-0">Settings</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label">BCC registration ackgnowledgment email(s) (separated by comma)</label>
                          <Input
                            id="registrationEmailNotifications"
                            name="registrationEmailNotifications"
                            value={this.state.registrationEmailNotifications || ""}
                            onChange={ (e) => this.handleChange(e) }
                            placeholder="Emails"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label">BCC final hotel and travel confirmation email(s) (separated by comma)</label>
                          <Input
                            id="adminBCCFinalConfirmation"
                            name="adminBCCFinalConfirmation"
                            value={this.state.adminBCCFinalConfirmation || ""}
                            onChange={ (e) => this.handleChange(e) }
                            placeholder="Emails"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label">Email schedule template</label>
                          {this.state.emailScheduleTemplate && <i className="fas fa-check" style={{ marginLeft: 10, color: "green"}} />}
                          <Input
                            id="emailScheduleTemplate"
                            name="emailScheduleTemplate"
                            onChange={ (e) => this.setState({ template: e.target.files[0] }) }
                            type="file"
                            accept=".docx"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col />
                      <Col lg="auto">
                        <Button type="submit" color="dark" disabled={this.context.loading}>Submit</Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Form>
              </Card>
            </div>
          </Row>
          <Modal isOpen={!!this.state.error}>
            <ModalHeader toggle={() => { this.setState({error: null}) }}>Error</ModalHeader>
            <ModalBody>
              {this.state.error}
            </ModalBody>
          </Modal>
        </Container>}
      </>
    );
  }
}

export default Buyers;
