/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Redirect } from "react-router-dom";

import { UserContext } from "../lib/UserContext";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";

class Index extends React.Component {
  static contextType = UserContext;
  state = {};

  render() {
    // Check if user is logged in.
    // If so, redirect to dashboard.
    if (this.context.user) {
      return <Redirect to="/admin/dashboard" />;
    }

    return (
      <>
        <IndexNavbar />
        <div className="main-content">
          <IndexHeader />
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Index;
