import { AmplifyConfig } from "./amplify";
import { APIConfig } from "./apiConfig";


// Get AWS Amplify settings.
const amplify = AmplifyConfig[process.env.NODE_ENV || "development"];
const apiConfig = APIConfig[process.env.NODE_ENV || "development"];

// Creating a namespace for our configs.
export { amplify, apiConfig };
