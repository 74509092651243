/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

// Auth imports.
import { Auth } from "aws-amplify";
import { UserContext } from "../../../lib/UserContext";

class Register extends React.Component {
  static contextType = UserContext;
  state = {
    name: "",
    email: "",
    password: "",
    verifyPassword: "",
    loginFailed: false,
    disableSubmitButton: false
  };

  handleInputChange = (event) => {
    // Reset login error message.
    this.setState((s) => ({...s, loginFailed: false }));

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  // Handle form submission.
  handleSubmit = async (event) => {
    event.preventDefault();

    if(this.state.verifyPassword !== this.state.password) {
      this.setState((s) => ({ ...s, error: "Passwords to not match" }));
    }

    // Disable login button.
    this.setState((s) => ({ ...s, disableSubmitButton: true }));

    try {
      const user = await Auth.signUp({
        username: this.state.email, 
        password: this.state.password,
        attributes: {
          email: this.state.email,
        }
      });


      // Update our user context provider.
      // Once this state change is picked
      // up by React, it will automatically
      // redirect user to dashboard.
      this.context.setState((state) => ({ ...state, user, disableSubmitButton: false }));
    } catch (error) {
      this.setState((s) => ({ ...s, loginFailed: true, error: error.message, name: "", email: "", password: "", verifyPassword: "", disableSubmitButton: false }));
    }
  };

  render() {
    return (
      <>
        <AuthHeader />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Register</small>
                  </div>
                  <Form role="form" onSubmit={this.handleSubmit}>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          placeholder="Email"
                          type="email"
                          required
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          placeholder="Password"
                          type="password"
                          required
                          value={this.state.password}
                          onChange={this.handleInputChange}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedVerifyPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="verifyPassword"
                          placeholder="Verify Password"
                          type="password"
                          required
                          value={this.state.verifyPassword}
                          onChange={this.handleInputChange}
                          onFocus={() =>
                            this.setState({ focusedVerifyPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedVerifyPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <small className="text-red">{this.state.error}</small>
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckRegister"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span className="text-muted">
                              I agree with the{" "}
                              <a
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button className="my-4" color="dark" type="submit" disabled={this.state.disableSubmitButton}>
                        Register
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Register;
